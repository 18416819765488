@import './variables.less';

.todoDatePicker {
  .leftSelectTitle {
    display: none !important;
  }
  .SelectDateRange {
    min-height: 18px;
    .anticon-calendar {
      display: none;
    }
    .ant-calendar-picker-input.ant-input {
      background-color: #fff !important;
      padding: 0 !important;
      text-align: center;
      &[value=''] {
        display: none;
        + .anticon {
          margin: auto;
          top: 0;
          display: block;
          bottom: 0;
          position: relative;
          right: 0;
          cursor: pointer;
          svg {
            fill: #979797;
          }
          &.anticon-calendar {
            display: block;
          }
        }
      }
      &:hover,
      &:focus {
        box-shadow: none !important;
      }
    }
    .ant-calendar-picker-clear {
      opacity: 1;
      background-color: transparent !important;
    }
    &:hover,
    &:focus {
      .ant-input {
        border: none !important;
      }
    }
  }
}
.todoTableList {
  table {
    tr {
      th {
        // &:first-child {
        //   text-align: left;
        // }
      }
      td {
        &:first-child {
          text-align: left;
        }
        .todoListTitle {
          color: #454545;
          font-weight: 500;
          .todoBasicDetail {
            font-weight: 400;
            margin-top: 5px;
            font-size: 13px;
            text-align: left;
          }
          .todoListTopBlock {
            .ant-checkbox {
              .ant-checkbox-inner {
                width: 16px !important;
                height: 16px !important;
              }
            }
            .todoListname {
            }
          }
        }
      }
    }
  }
}
.kycViewCustomer {
  .kycViewTitle {
    padding: 10px;
    background-color: #fff;
  }
}
.todoSidebar {
  .ant-drawer-content-wrapper {
    padding: 0;
  }
}
.tofoSelectOption {
  .formSelect {
    margin-bottom: 0;
  }
  .ant-select {
    padding: 0 !important;
    border-bottom: none !important;
    .ant-select-selection-selected-value {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
}

.setPriorityBox {
  .ant-select-selection {
    border: none;
  }
  .priorityItem {
    .priorityImg {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
  .ant-select-selection__rendered {
    margin-left: 0;
  }
  .ant-select {
    width: 100%;
    border-bottom: 1px solid #e4e4e4;
  }
}
