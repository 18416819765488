@import '../../styles/variables.less';

.backOption {
  background-color: @themeColor;
  padding: 4px 10px;
  border-radius: 15px;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  color: #fff;
  img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
  &:hover {
    color: #fff;
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;