.inpBlock {
  display: flex;
  flex-direction: row;
  .inputBlock {
    width: 50%;
    height: 65px;
    padding-right: 20px;
  }
  .commonTextArea {
    width: 465px;
    height: 40px;
    padding-right: 20px;
  }
}
.datepicker {
  display: flex;
  flex-direction: row;
  .DatePickerBlock .ant-calendar-picker {
    padding-right: 15px;
  }
  .DatePickerBlock .ant-calendar-picker .ant-input {
    width: 465px;
    height: 60px;
  }
  .formSelect .ant-select {
    width: 460px;
    .ant-select-selection--multiple .ant-select-selection__placeholder {
      margin-left: 10px;
    }
  }
}
.endBlock {
  display: flex;
  flex-direction: row;
  .fileuploadblk {
    width: 350px;
    display: flex;
    flex-direction: row;
  }
  .checkBoxForm {
    position: sticky;
    margin-left: 20px;
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;