@import 'variables.less';

.settingMainWrapper {
  // width: 100vw;
  // height: 100vw;
  // position: fixed;
  // left: 0;
  // right: 0;
  // top: 0;
  // z-index: 100;
  // background-color: #fff;
  // display: flex;
  // flex-wrap: wrap;
  .settingLeftScroll {
    height: 100vh;
    .scrollarea-content {
      height: 100%;
    }
    .scrollbar-container {
      opacity: 0;
    }
  }
  .settingLeftBlock {
    background-color: @themeLightColor;
    padding: 40px;
    width: 250px;
    height: 100%;
    overflow: auto;
    .seetingMainTitle {
      color: #424242;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 1px;
      margin: 20px 0;
    }
    ul {
      padding: 0;
      li {
        list-style: none;
        a {
          margin: 5px 0;
          padding: 10px 0;
          display: block;
          color: @textDark;
          font-size: 16px;
          &.active {
            color: #000;
            font-weight: 600;
          }
        }
      }
    }
    .settingSpereter {
      width: 100%;
      height: 1px;
      background-color: rgba(192, 158, 124, 0.26);
      margin: 20px 0;
    }
    .settingSubTitle {
      font-weight: 600;
      letter-spacing: 1px;
      color: @themeColor;
      text-transform: uppercase;
      font-size: 15px;
      margin-bottom: 20px;
    }
  }
  .settingRightBlock {
    width: calc(~'100% - 250px');
    .settingRightInneer {
      padding: 20px 30px;
      overflow: auto;
      max-height: 100vh;
    }
  }
}
.quickSearchTable .settingLeftScroll .content {
  overflow: auto;
}
.headeTitle {
  h2 {
    color: #424242;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0 0 20px 0;
  }
}
.commonDec {
  p {
    color: #aeacb0;
    font-size: 14px;
    a {
      color: @themeColor;
      text-decoration: underline;
      font-weight: 600;
    }
  }
}

.settingSecondTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.submasterTab {
  .leftArrow {
    margin-left: 15px;
  }
}
.subMasterTable {
  padding-left: 25px;
}
.lastEditUser {
  .lastEditUserName {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .userName {
    font-size: 12px;
  }
  .infoIcon {
    margin-left: 5px;
    img {
      width: 10px;
      height: 10px;
    }
  }
}
.subMasterHeader {
  padding: 10px;
}

.homeSettingWrapper {
  .from-group {
    width: 50%;
    padding-right: 10px;
  }
}
.discountSetting {
  width: 50%;
  .from-group {
    width: 50%;
    padding-right: 10px;
  }
}
.imageBlockWrapper {
  width: 50px;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
}
.homeSettingfooter {
  width: calc(~'100% - 250px');
  left: 250px;
  bottom: 0;
}
.uploadDiamonds {
  .select-file {
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;
    input[type='file'] {
      width: 100%;
      height: 100%;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: 0;
      cursor: pointer;
      left: 0;
      top: 0;
    }
    .upload_images_content {
      padding: 12px;
      text-align: center;
      img {
        width: 40px;
      }
      .title {
        margin-bottom: 0;
        margin-top: 10px;
        font-weight: 600;
        color: #000;
        font-size: 16px;
      }
      .desc {
        margin-bottom: 0;
      }
    }
  }
  .rdoOption {
    .ant-radio-group {
      display: block;
    }
    .rdoBtn {
      border: 1px solid #e9e9e9;
      padding: 10px 15px;
      border-radius: 10px;
      display: flex;
      .subTitle {
        margin-bottom: 0;
      }
      & + .rdoBtn {
        margin-top: 10px;
      }
      .desc {
        margin-bottom: 0;
      }
    }
  }
}
