@import '../../styles/variables.less';

.searchResultListWrapper {
  position: relative;
  .searchInnerResult {
    background-color: @tableBackGroundColor;
    // height: calc(~"100vh - 46px");
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;