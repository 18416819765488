@import 'variables.less';

.contactDirectoryWrapper {
  margin-top: 40px;
  .contactListMain {
    margin-top: 30px;
  }
  .contactList {
    height: calc(~'100vh - 219px');
    overflow: auto;
    table {
      width: 100%;
      tr {
        th {
          min-width: 120px;
          text-transform: uppercase;
          color: #a2a2a2;
          font-weight: normal;
          letter-spacing: 1px;
          font-size: 13px;
          border-bottom: 1px solid #e9e9e9;
          padding: 5px 5px 10px 5px;
          white-space: nowrap;
          position: sticky;
          top: 0;
          background-color: #fff;
          &:first-child {
            min-width: min-content;
          }
        }
        td {
          padding: 15px 5px;
          border-bottom: 1px solid #e9e9e9;
        }
      }
    }
  }
}
