@import 'variables.less';

.diamondDetailPopup {
  z-index: 1000000;
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1 !important;
  }
  &.ant-drawer {
    .ant-drawer-content {
      overflow: visible;
    }
  }
  .ant-drawer-header-no-title {
    .ant-drawer-close {
      background: #fff;
      right: auto;
      left: -46px;
      border-radius: 20px;
      top: 8px;
      width: 40px;
      height: 40px;
      line-height: 42px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-drawer-content-wrapper {
    width: 74% !important;
    padding: 0;
    height: 100%;
    .ant-drawer-body {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
  .diamondDetailWrapper {
    .diamondDetailTopTab {
      position: absolute;
      left: 0;
      top: -45px;
      border-bottom: none;
      z-index: 10000;
    }
  }
  .diamondDetailLeft {
    width: 50%;
    position: relative;
    z-index: 10001;
    .diamondDetailInnerDetail {
      position: relative;
      top: 0;
      .tab-content {
        height: calc(~'100vh - 80px');
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
  .diamondDetailRight {
    max-height: calc(~'100vh - 73px');
    overflow: auto;
    background-color: transparent;
  }
  .diamondDetailBottomBox {
    box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
    z-index: 1000;
    top: 0;
    background: #fff;
    width: 100%;
    .diamondDetailBottomAction {
      width: 100%;
    }
  }
}
.diamondDetailWrapper {
  // width: 100%;
  // height: 100%;
  // position: fixed;
  // z-index: 10000;
  // left: 0;
  background: #fff;
  // overflow: auto;
  .diamondDetailTopTab {
    justify-content: center;
  }
  .diamondDetailInner {
    padding: 0 25px;
    .diamondDetailMainTab {
      display: flex;
    }
    .basicDetailFull {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      margin-bottom: 0;
      li {
        font-size: 20px;
        font-weight: 600;
        font-weight: 500;
        list-style: none;
        margin: 0 5px;
      }
    }
    .basicSmallDetail {
      padding: 5px;
      margin-top: 2px;
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .diamondDetailShare {
      margin-top: 8px;
      .diamondDetailShareItem {
        display: flex;
        align-items: center;
        position: relative;
        margin-right: 30px;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          margin-left: 10px;
        }
        span {
          display: block;
          // color: @themeColor;
          font-size: 16px;
          // font-family: @titlFont;
          text-transform: uppercase;
        }
        &:after {
          content: '';
          width: 1px;
          height: 20px;
          background-color: #ddd;
          position: absolute;
          right: -15px;
        }
        &:last-child {
          &:after {
            content: none;
          }
        }
      }
    }
  }
}
.detailTopBasic {
  .detailPriceBlock {
    span {
      font-size: 18px;
      font-weight: 500;
      &.offerPrice {
        margin-left: 10px;
        font-size: 14px;
        color: #6bc950;
        font-weight: 400;
      }
    }
  }
}
.diamondDetailLeft {
  width: 50%;
  position: relative;
  // z-index: 10001;
  // height: calc("100vh - 131px");
  // overflow: auto;
  .diamondDetailInnerDetail {
    // height: calc(100vh - 73px);
    // overflow-y: auto;
    // overflow-x: hidden;
    position: sticky;
    top: 0;
    .tab-content,
    .tab-pane {
      height: 100%;
    }
    .diamondDetailImageBox {
      height: 100%;
      padding: 20px 10px;
      text-align: center;
      &.htmlViewImage {
        height: 584px;
      }
      .magnifier {
        width: 500px !important;
        height: 500px !important;
        margin: auto;
        overflow: hidden;
      }
      img,
      iframe {
        width: 100%;
        height: 100%;
        margin: auto;
        object-fit: contain;
        border: none;
        &.certificateImage {
          height: auto;
        }
      }
      .imageBlock {
        max-width: 100%;
        height: 100% !important;
        img {
          width: 90%;
          height: 100%;
        }
      }
    }
    .smallSliderBlock {
      margin-top: 15px;
      padding: 0 55px;
      .slick-list {
        max-height: 480px;
        .slick-cloned {
          display: none;
        }
      }
      .slick-track {
        margin: auto;
      }
      .smallSliderImage {
        width: 60px !important;
        height: 60px;
        display: flex !important;
        margin: auto;
        align-items: center;
        justify-content: center;
        border: 2px solid #fff;
        // border-radius: 5px;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
        }
      }
      &.smallSliderBlockimage {
        .smallSliderImage {
          // width: 80px !important;
          // height: 80px;
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
      .slick-track {
        display: flex;
        justify-content: center;
        .slick-slide {
          // width: 80px !important;
          height: 80px;
          margin: 0 0;
          &.slick-current {
            .smallSliderImage {
              border: 2px solid @themeColor;
            }
          }
        }
      }
      .slick-prev,
      .slick-next {
        width: 30px;
        height: 30px;
        background: rgba(0, 0, 0, 0.5);
        &:before {
          color: #fff;
        }
        &.slick-prev {
          left: 10px;
        }
        &.slick-next {
          right: 10px;
        }
      }
      .smallimgTitle {
        color: #454545;
        font-size: 12px;
        text-transform: uppercase;
        margin-top: 5px;
        display: block;
        text-align: center;
      }
    }
  }
  .tab-pane {
    display: none;
    &.active {
      display: block;
    }
  }
}
// .diamondDetailRight {
//   width: 50%;
//   // max-height: calc(100vh - 73px);
//   // overflow: auto;
//   position: relative;
//   z-index: 1;
//   padding-left: 50px;
//   // background-color:#fafafa;
//   .diamondDetailMore {
//     padding: 20px;
//     .detailSmallTitle {
//       font-size: 22px;
//       color: @themeColor;
//       margin-bottom: 0;
//       text-transform: capitalize;
//       font-weight: 600;
//       letter-spacing: 1px;
//       margin-bottom: 20px;
//     }
//     .diamondDetailMoreHalf {
//       display: flex;
//       flex-wrap: wrap;
//       justify-content: space-between;
//       ul {
//         width: 49%;
//         padding: 0;
//         // margin: 0 0 10px 0;
//         li {
//           display: flex;
//           flex-wrap: wrap;
//           align-items: center;
//           margin-bottom: 10px;
//           span {
//             color: #000;
//             font-size: 14px;
//             font-weight: normal;
//             &.detailLabel {
//               width: 115px;
//               margin-right: 5px;
//               font-size: 14px;
//               letter-spacing: 0.5px;
//               // text-transform: uppercase;
//             }
//             &.detailvalue {
//               font-size: @fonth6;
//               color: @themeColor;
//               font-weight: 500;
//               word-break: break-word;
//               width: calc(~"100% - 120px");
//             }
//           }
//         }
//       }

//       &.diamondFullBlock {
//         ul {
//           width: 100%;
//           li {
//             span {
//               &.detailLabel {
//                 width: 190px;
//               }
//               &.detailvalue {
//                 width: calc(~"100% - 195px");
//               }
//               &.addNotesOption {
//                 display: flex;
//                 align-items: center;
//                 textarea {
//                   width: calc(~"100% - 110px");
//                   border: 1px solid #e2e2e2;
//                   background-color: #fff;
//                   height: 80px;
//                   border-radius: 5px;
//                   margin-right: 10px;
//                   padding: 10px;
//                   &:focus {
//                     outline: none;
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
.detailHalfWhite {
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
}
.detailHalfGray {
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f6f6f6;
}
.diamondDetailSummary {
  width: 100%;
  .botoomStickyBar {
    display: none !important;
  }
}
.diamondDetailBottomBox {
  position: sticky;
  top: 0;
  padding: 10px;
  // background: #fafafa;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  .diamondDetailBottomAction {
    width: auto;
    height: 53px;
    // border-bottom: 1px solid #e2e2e2;
    border-top: none !important;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    .diamondDetailTab {
      border-bottom: none !important;
    }
    .diamondDetailActionItem {
      display: flex;
      align-items: center;
      margin: 5px;
      cursor: pointer;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid @themeColor;
      background-color: @themeColor;
      span {
        // color: #fff !important;
        // margin-left: 10px;
      }
      // &:after{
      //   content: "";
      //   width: 1px;
      //   height: 20px;
      //   background-color: #ddd;
      //   position: absolute;
      //   top: 0;
      //   bottom: 0;
      //   left: 0;
      //   margin: auto;
      // }
      // &:first-child{
      //   &:after{
      //     content: none;
      //   }
      // }
      img {
        width: 20px;
        height: 20px;
        // margin-right: 5px;
      }
      span {
        color: #212431;
        font-weight: 600;
      }
    }
  }
}
.tooltipShow {
  z-index: 100001;
}
//   popup Detail
.diamondDetailRightPopup {
  padding: 15px;
}
.diamondDetailPage {
  margin-top: 10px;
  .sideBarPopupButton {
    position: fixed;
    bottom: 0;
    width: 50%;
    right: 0;
    left: auto;
    box-shadow: none;
    background-color: transparent;
  }
}
.diamondDetailBottomAction {
  margin: 16px 0;
  .diamondDetailActionItem {
    margin-bottom: 16px;
    max-width: 50%;
    width: 200px;
    margin-right: 10px;
    text-align: center;
    .arrowIcon {
      width: 14px;
      height: 14px;
    }
  }
}
.detailCompareBlock {
  margin: 30px auto;
  max-width: 1440px;
  .compareMainPage {
    position: relative;
    height: auto;
    width: 100%;
    .compareTable {
      height: auto;
      padding-top: 0;
      tr {
        &:first-child {
          td {
            &:first-child {
              text-align: left;
            }
          }
        }
        td {
          text-align: center;
          padding: 12px 10px;
          &:first-child {
            position: relative;
          }
        }
        .compareTableHead {
          .infomTootip {
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
        }
        .linkText {
          text-decoration: underline;
          text-transform: uppercase;
          font-size: 16px;
          color: #000;
        }
      }
    }
  }
}

.diamondDetailBlock {
  margin: 50px auto;
  max-width: 1440px;
  .diamondDetailMore {
    width: calc(~'33.33% - 30px');
    padding: 0 30px 0 10px;
    border-right: 1px solid #ddd;
    margin-right: 30px;
    .detailSmallTitle {
      // color: @themeColor;
      font-size: 18px;
      font-weight: 400;
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
    }
    .diamondDetailMoreHalf {
      ul {
        padding: 0;
        margin: 0;
        li {
          padding: 8px 0;
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          list-style: none;
          span {
            &.detailLabel {
              // font-family: @titlFont;
              font-size: 16px;
            }
            &.detailvalue {
              font-size: 16px;
            }
          }
        }
      }
    }
    &:last-child,
    &:nth-last-child(2) {
      padding-right: 0;
      margin-right: 0;
      border-right: none;
    }
    &:last-child {
      margin-top: 20px;
      width: 100%;
    }
  }
}
.diamondetailTable {
  margin-top: 35px;
  .diamondDetailbasicWrap {
  }
  .diamondDetailBasic {
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 48%;
    border-bottom: 1px solid #e8e8e8;
    color: @themeColor;
    display: flex;
    justify-content: space-between;
    .diamondDetailLebl {
      font-size: 18px;
    }
    .diamondDetailValue {
      font-size: 16px;
    }
    // &:nth-child(odd){
    //   position: relative;
    //   &:after{
    //     content: "";
    //     width: 1px;
    //     height: 100%;
    //     background-color: #e8e8e8;
    //     right: -15px;
    //     position: absolute;
    //   }
    // }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.diamondDetailVersion2 {
  .mainHeadingTitle {
    margin-bottom: 20px;
    font-size: 20px;
  }
  //   font-family:VerlagBook !important;
  //   font-weight: 400 !important;
  //   font-variant-numeric: lining-nums;
  //   h2{
  //     font-family:VerlagBook !important;
  //   }
  //   .commonButton,
  //   .commonOutline{
  //     font-size: 16px;
  //     text-transform: uppercase;
  //     width: 200px;
  //     max-width:50%;
  //     text-align: center;
  //     letter-spacing: 0.5px;
  //     line-height: 20px;
  //     padding: 10px;
  //     display: block;
  //     align-items: center;
  //   }
  //   .mainHeadingTitle{
  //     display: block;
  //     width: 100%;
  //     font-size: @fonth1;
  //     margin: 24px 0;
  //     letter-spacing: 1px;
  //     line-height: 40px;
  //     color: @themeColor;
  //     padding: 0;
  //     font-weight: 400;
  //     text-decoration: none;
  //   }
  &.diamondDetailWrapper {
    .diamondDetailInner {
      .detailBasic {
        margin-bottom: 16px;
      }
    }
  }
  .diamondDetailLeft {
    width: 60%;
    .diamondDetailInnerDetail {
      position: relative;
      .leftBigImage {
        float: left;
        width: calc(~'100% - 100px');
      }
      .smallSliderBlock {
        float: left;
        width: 100px;
        padding: 35px 0;
        .slick-prev {
          bottom: auto;
          top: 0;
          left: 0 !important;
          right: 0;
          margin: auto;
          transform: rotate(90deg);
        }
        .slick-next {
          top: auto;
          bottom: 0;
          left: 0;
          right: 0 !important;
          margin: auto;
          transform: rotate(90deg);
        }
      }
      .smallSliderBlock {
        .slick-track {
          display: block;
        }
        .smallSliderImage {
          width: 70px !important;
          height: 70px;
          border: 1px solid #e8e8e8;
          img {
            width: 100%;
            height: 100%;
          }
          &.htmlVideo {
            img {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
    .diamondDetailRight {
      width: 40%;
    }
  }
  .diamondDetailPage {
    margin-top: 15px;
  }
  .recentlyViewProduct {
    margin: 50px auto;
    max-width: 1440px;
    .daimondGridViewItem {
      .DiamondGridViewImg {
        display: flex;
        img {
          height: 100%;
          margin: auto;
        }
      }
    }
    .slick-list {
      .slick-track {
        margin: 0;
      }
    }
    .slick-dots {
      margin-top: 10px;
      li {
        position: relative;
        display: inline-block;
        width: 10px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
          background-color: #ddd;
          border-radius: 100%;
          width: 6px;
          height: 6px;
          padding: 0;
          &:before {
            content: none;
          }
        }
        &.slick-active {
          button {
            background-color: @themeColor;
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}

.detailPopover {
  width: 300px;
  .ant-popover-title {
    h2 {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }
    img {
      width: 10px;
      height: 10px;
      cursor: pointer;
    }
  }
  .ant-popover-inner-content {
    font-size: 14px;
  }
}
.moreLink {
  font-size: 14px;
  text-decoration: underline;
  text-align: center;
  width: 100%;
  display: block;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  text-transform: uppercase;
}

// grid View
.diamondGridView {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  .daimondGridViewItem {
    width: calc(~'25% - 10px');
    margin: 5px;
    border: 1px solid #e2e2e2;
    // padding: 10px;
    border-radius: 10px;
    .DiamondGridViewImg {
      width: 100%;
      height: 250px;
      // padding: 10px;
      background: #fafafa;
      border-radius: 10px 10px 0 0;
      position: relative;
      .diamondGrdiCheckbox {
        position: absolute;
        left: 10px;
        top: 10px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px 10px 0 0;
      }
    }
    .gridViewContent {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid #e2e2e2;
      padding-top: 10px;
      /* margin-top: 15px; */
      padding: 10px;
      .gridLeftContent {
        width: 60%;
        text-align: left;
        h2 {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 5px;
          line-height: 20px;
        }
        a {
          font-size: 14px;
          color: #000;
          display: inline-block;
        }
        p {
          margin: 0;
          font-size: 14px;
        }
      }
      .gridRightContent {
        width: 40%;
        text-align: right;
        color: 14px;
        a {
          font-weight: 500;
          font-size: 14px;
          color: #000;
        }
        span {
          display: block;
          font-weight: 500;
          font-size: 14px;
        }
        .statusShow {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 14px;
          margin-top: 8px;
          font-weight: 400;
          .statusIcon {
            margin-left: 5px;
            width: 8px;
            height: 8px;
            border-radius: 2px;
            display: block;
            background: #6bc950;
            &.red {
              background-color: #db1c1c;
            }
            &.blue {
              background-color: #307bea;
            }
          }
        }
      }
    }
  }
}
.gridListIcon {
  margin-right: 10px;
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
  }
}
.bid-winner {
  background-color: #cae9b9 !important;
}
