@import '../../styles/variables.less';

.formSelect {
  margin-bottom: 15px;
  &.groupSelection {
    .ant-select {
      .ant-select-search__field {
        background: transparent;
      }
    }
    .ant-select-selection--multiple {
      .ant-select-arrow {
        top: 30px;
      }
    }
  }
  label {
    color: #5e5e5e;
    font-size: 12px;
    display: block;
    width: 100%;
    font-weight: 600;
  }
  &.multiSelect {
    width: 80%;
    padding: 5px 0;
    // height: 36px;
    .ant-select {
      width: 100%;
      padding: 4px 0;
      .ant-select-selection {
        padding-bottom: 0;
        &:active,
        &:focus {
          box-shadow: none;
        }
        .ant-select-selection__rendered {
          height: 35px;
          .ant-select-search__field__wrap {
            input {
              width: 0;
              padding: 0;
            }
          }
          .ant-select-selection__placeholder {
            font-weight: normal !important;
          }
          .ant-select-selection__placeholder {
            margin-left: 0;
            font-size: 14px;
            font-weight: 600;
            color: #a0a2a6 !important;
          }
          ul {
            // display: flex;
            // align-items: center;
            li {
              &.ant-select-selection__choice {
                background: #fafafa;
                border-radius: 5px;
                color: #000;
              }
            }
          }
        }
        &:hover {
          border: none !important;
          // border-bottom: 1px solid #d9d9d9 !important;
        }
      }
    }
  }
  .ant-select {
    width: 100%;
    border-bottom: 1px solid #e4e4e4;
    padding: 14px 0;
    .ant-select-search__field {
      background-color: #fff;
    }
    .ant-select-selection {
      padding: 0;
      border: none;
      &.ant-select-selection--single {
        height: auto;
        border: none;
        outline: none;
        box-shadow: none;
        box-shadow: none !important;
        .ant-select-selection__rendered {
          line-height: 16px;
          margin: 0;
          font-size: 14px;
          color: rgba(34, 34, 34, 0.6);
        }
        .ant-select-arrow {
          svg {
            fill: @themeColor;
          }
        }
      }
    }
  }
}
.error-message {
  position: relative;
  .ant-select {
    border-bottom: 1px solid #ee3b3b !important;
    input {
      border-bottom: none !important;
    }
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;