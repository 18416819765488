@import '../variables.less';
#root {
  height: 100vh;
}
body {
  background-color: #ffffff !important;
}
h1 {
  margin-bottom: 0;
  font-size: 45px;
  font-weight: bold;
}
.adma-login-wrap {
  // background: -webkit-linear-gradient(left, #dfe0df 50%, @themeColor 50%);
  background: #fff;
  height: 100%;
  position: relative;
}
.loginpage .adma-app-login-content .ant-input,
.loginpage .adma-app-login-content .ant-input:focus {
  border: 1px solid #ededed !important;
  background: #fff !important;
  border-radius: 3px !important;
  padding: 6px 15px;
  height: 50px;
  box-shadow: none;
}
.adma-login-main-content {
  width: 100%;
  // width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-title {
  font-size: 14px;
  margin-bottom: 30px;
  margin-top: 10px;
  letter-spacing: 0.2px;
}
.adma-app-login-content .ant-form-item-children .forget-pwd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    color: @themeColor;
  }
}
.adma-login-wrap .loginpage .submit-btn .ant-btn-primary {
  background-color: @themeColor !important;
  border-color: @themeColor !important;
  border-radius: 3px;
  width: auto;
}
.adma-app-login-content {
  width: 100%;
  // padding: 100px 60px;
}
.forget-pwd .ant-checkbox-checked .ant-checkbox-inner {
  background-color: @themeColor !important;
  border-color: @themeColor !important;
}
.loginpage .submit-btn .ant-btn-primary:not([disabled]):not(.disabled):active,
.loginpage .submit-btn .ant-btn-primary:not([disabled]):not(.disabled).active,
.loginpage .submit-btn .ant-btn-primary:hover,
.loginpage .submit-btn .ant-btn-primary:focus {
  color: @themeColor !important;
  border-color: @themeColor !important;
  background-color: #fff !important;
}
.submit-btn .ant-btn {
  padding: 24px 30px;
  line-height: 0;
  font-size: 18px;
  width: auto;
}
.adma-logo-content-bg {
  margin-bottom: 80px;
}
.adma-logo-content-bg img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.adma-login-container {
  width: 100%;
  // height: 100%;
  // margin: 0 auto;
  // width: 80%;
  // margin: 0 auto;
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // -webkit-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);
}
.adma-login-top-cover-banner {
  background-image: url(../../assets/images/login/top-banner.png);
  width: 100%;
  height: 434px;
  object-fit: cover;
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  .adma-login-top-banner {
    // background-image: url(../../assets/images/login/top-banner.png);
    // width: 100%;
    // height: 434px;
    // object-fit: cover;
    // background-repeat: no-repeat;
    // position: relative;
    // background-size: 100% 100%;
    // background-image: url(../../assets/images/login/top-banner.png);
    width: 50%;
    // width: 60%;
    margin: auto;
    height: 100%;
    position: relative;
  }
  // .adma-login-top-banner {
  position: relative;
  .admin-login-position {
    position: absolute;
    // left: 50%;
    right: 0%;
    // right: 50%;
    top: 50%;
    // -webkit-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    // -webkit-transform: translate(-100%, -25%);
    // transform: translate(-100%, -25%);
    -webkit-transform: translate(0%, 20%);
    transform: translate(0%, 20%);

    h3 {
      font-size: 30px;
      color: #fff;
      margin-bottom: 0.1em;
      // margin-bottom: 0.3em;
    }
    a {
      font-size: 12px;
      color: #8c7459;
    }
    .text-center {
      width: 100%;
      text-align: center;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
  // }
}

.loginpage {
  // display: -ms-flexbox;
  // display: flex;
  // background: #fff;
  // width: 60%;
  width: 30%;
  // width: 100%;
  // height: 100%;
  margin: 0 auto;
  // border-radius: 0px;
  // position: relative;
}
// .loginpage:before,
// .loginpage:after {
//   content: '';
//   position: absolute;
//   background-image: url(../../assets/images/login-png.png);
//   width: 120px;
//   height: 120px;
//   background-size: 100%;
//   opacity: 0.1;
//   z-index: -1;
// }
// .loginpage:before {
//   left: -50px;
//   top: -50px;
// }
// .loginpage:after {
//   right: -50px;
//   bottom: -50px;
// }
.login-right-section {
  width: 50%;
  // width: 50%;
  position: relative;
  background: #e0e1e0 !important;
  border-radius: 0 40px 40px 0;
  overflow: hidden;
  video {
    width: 100%;
    object-fit: cover;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
.loginpage .login-right-section:before {
  content: '';
  background-image: url('../../assets/images/login-rg.png');
  width: 100%;
  height: 100%;
  background-size: 80%;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
}
.adma-signin-form .ant-col {
  width: 100%;
  padding: 0 !important;
}
.login-right-section .media {
  position: absolute;
  background: #fff;
  z-index: 1;
  border-radius: 3px;
  bottom: 20px;
  width: 70%;
  display: flex;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.05);
}
.loginpage .login-right-section .media-left {
  width: 140px;
  background: @themeColor;
  text-align: center;
  line-height: 80px;
  border-radius: 3px 0 0 3px;
}
.login-right-section .media-left img {
  width: 50px;
}
.login-right-section .media-body {
  padding: 15px;
}
.login-right-section .media-body p {
  margin-bottom: 0;
  font-size: 14px;
  color: #787777;
  line-height: 16px;
}
.login-right-section .serives-name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.login-title {
  margin-top: 20px;
}
.login-title span {
  color: #c0c0c0;
}
.submit-btn > div {
  margin-bottom: 0;
}
.loginpage .ant-checkbox:hover .ant-checkbox-inner {
  border-color: @themeColor;
}
.loginpage .ant-form .ant-row {
  margin-left: 0;
  margin-right: 0;
}
.adma-signup-form-forgot {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.adma-signup-form-forgot:hover {
  color: @themeColor;
}
.forget-pwd .submit-btn,
.loginpage .forget-pwd {
  justify-content: space-between;
}
.forget-pwd {
  .submit-btn {
    a {
      color: @themeColor;
    }
  }
}
@media (max-width: 1599px) {
  .adma-login-top-cover-banner {
    background-image: url(../../assets/images/login/top-banner.png);
    width: 100%;
    height: 300px;
    object-fit: cover;
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
    // background-image: url(../../assets/images/login/top-banner.png);
  }
  // .adma-login-top-banner {
  // }
}
@media (max-width: 1399px) {
  .adma-login-top-cover-banner {
    background-image: url(../../assets/images/login/top-banner.png);
    width: 100%;
    height: 300px;
    object-fit: cover;
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
    // background-image: url(../../assets/images/login/top-banner.png);
  }
  // .adma-login-top-banner {
  // }
}
@media (max-width: 1299px) {
  .adma-login-top-cover-banner {
    background-image: url(../../assets/images/login/top-banner.png);
    width: 100%;
    height: 300px;
    object-fit: cover;
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
    // background-image: url(../../assets/images/login/top-banner.png);
  }
  // .adma-login-top-banner {
  // }
}
@media (max-width: 1199px) {
  .adma-login-top-cover-banner {
    background-image: url(../../assets/images/login/top-banner.png);
    width: 100%;
    height: 250px;
    object-fit: cover;
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
    // background-image: url(../../assets/images/login/top-banner.png);
  }
  // .adma-login-top-banner {
  // }
}
@media (max-width: 1024px) {
  .adma-login-top-cover-banner {
    background-image: url(../../assets/images/login/top-banner.png);
    width: 100%;
    height: 210px;
    // height: 250px;
    object-fit: cover;
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
    // background-image: url(../../assets/images/login/top-banner.png);
  }
  // .adma-login-top-banner {
  // }
}
@media (max-width: 768px) {
  .adma-login-top-cover-banner {
    background-image: url(../../assets/images/login/top-banner.png);
    width: 100%;
    height: 170px;
    object-fit: cover;
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
    // background-image: url(../../assets/images/login/top-banner.png);
  }
  // .adma-login-top-banner {
  // }
}

@media (max-width: 768px) {
  .adma-login-top-cover-banner {
    .adma-login-top-banner {
      width: 90%;
      margin: auto;
      height: 100%;
      position: relative;
    }
  }
  .loginpage {
    width: 90%;
    margin: auto;
  }
  .admin-login-position {
    h3 {
      font-size: 20px;
    }
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;