@import '../../styles/variables.less';

.DatePickerBlock {
  margin-bottom: 15px;
  .ant-calendar-picker {
    width: 100% !important;
    .ant-input {
      border: none;
      border-bottom: 1px solid #e4e4e4;
      border-radius: 0 !important;
      padding: 13px 0;
      box-shadow: none !important;
      height: auto;
      input {
        border: none !important;
        color: rgba(34, 34, 34, 0.6);
        text-align: left;
        &::placeholder {
          color: rgba(34, 34, 34, 0.6);
          text-align: left;
        }
      }
      .ant-calendar-range-picker-separator {
        ~ input {
          text-align: center;
          &::placeholder {
            text-align: center;
          }
        }
      }
    }
  }
}
.ant-calendar-time-picker-select li:hover {
  background: @themeLightColor !important;
}
.error-message {
  .error {
    color: #ee3b3b;
    font-size: 10px;
    margin-top: 3px;
    margin-bottom: 0;
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;