@import '../../styles/variables.less';

.todoPriority {
  .ant-select-selection {
    border: none;
    .ant-select-arrow {
      display: none;
    }
  }
  .priorityItem {
    .priorityTitle {
      display: none;
    }
    .priorityImg {
      width: 18px;
      height: 18px;
    }
  }
}
.priorityTitle {
  text-transform: capitalize;
}
.priorityDropdown {
  min-width: 140px;
  .priorityItem {
    padding: 11px 15px 11px 12px;
    border-bottom: 1px solid #f4f4f4;
    .priorityTitle {
      margin-left: 8px;
    }
    .priorityImg {
      width: 18px;
      height: 18px;
      &.closeImg {
        width: 18px;
        height: 8px;
      }
    }
  }
  ul {
    li {
      padding: 0;
      &:last-child {
        .priorityItem {
          border-bottom: none;
        }
      }
    }
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;