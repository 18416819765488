@import 'variables.less';

.diamondListinSidebar {
  .ant-drawer-content-wrapper {
    // width: 650px !important;
    background: #fff !important;
    .ant-drawer-wrapper-body {
      background: #fff !important;
      .searchPopupCommon {
        .mainHeadingTitle {
          margin-bottom: 20px;
          margin-right: 0;
        }
      }
    }
    .diamondImagePopup {
      .diamondDetailImageBox img {
        width: 100%;
        height: 80vh;
        object-fit: contain;
      }
      .smallSliderBlockimage {
        .slick-list {
          .slick-track {
            display: flex;
            justify-content: center;
            margin: 10px auto auto;
            .slick-slide {
              width: 80px !important;
              height: 80px;
              img {
                width: 35px;
                height: 35px;
                margin: auto;
                border-radius: 8px;
              }
              &.slick-current {
                .smallSliderImage {
                  border: 1px solid #000;
                }
              }
              .smallSliderImage {
                width: 60px !important;
                height: 60px;
                cursor: pointer;
                display: flex !important;
                margin: auto;
                border: 1px solid #ddd;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
    .ant-drawer-close {
      background: #fff;
      right: auto;
      left: 0;
      border-radius: 20px;
      top: 8px;
      width: 40px;
      height: 40px;
      line-height: 42px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.xl-size {
    .ant-drawer-content-wrapper {
      width: 90% !important;
    }
  }
  &.lg-size {
    .ant-drawer-content-wrapper {
      width: 65% !important;
    }
  }
  &.sm-size {
    .ant-drawer-content-wrapper {
      width: 34% !important;
    }
  }
  &.xs-size {
    .ant-drawer-content-wrapper {
      width: 25% !important;
    }
  }
  &.onlyImageSlider {
    .ant-drawer-content-wrapper {
      width: 800px !important;
    }
    .ant-drawer-body {
      width: 100%;
      height: 100%;
      iframe {
        border: none;
      }
    }
    iframe {
      border: none;
      height: calc(~'100vh - 30px');
    }
  }
  &.ant-drawer-open {
    .ant-drawer-close {
      left: -58px;
    }
  }
  .sidebarAction {
    position: absolute;
    bottom: 0;
    box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
    background: #fff;
    width: auto;
    padding: 10px;
    left: -15px;
    bottom: -15px;
    right: -15px;
    display: flex;
    justify-content: center;
    .commonButton {
      margin-right: 10px;
    }
  }
}
.popupHalfShow {
  height: calc(~'100vh - 140px');
  .searchResultListWrapper {
    height: calc(~'100% - 250px');
    .searchInnerResult {
      height: 100%;
    }
    .searchResultTable {
      height: 100% !important;
    }
  }
  .singleStonePlaceOrderDetail {
    // height: calc(~"50% - 20px");
    overflow: auto;
    .commonTextArea {
      height: 47px;
      margin-top: 0;
      resize: none;
    }
    &.applyMemo .from-group {
      width: 25%;
    }
  }
}
.addNotePopup {
  .searchResultListWrapper {
    height: 88%;
  }
}
.offerNote {
  width: 100%;
  color: #000;
}
.d-block {
  display: block !important;
}
