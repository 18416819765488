@import './variables.less';

.qrCodeOption {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 18px;
  width: 90px;
  padding: 12px 0 10px 0;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.19);
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  img,
  canvas {
    width: 50px;
    height: 50px;
    // margin: auto auto 30px auto;
    margin: auto;
    object-fit: contain;
  }
  span {
    color: #000;
    text-transform: uppercase;
    font-size: 11px;
    text-align: center;
    display: block;
    margin-top: 5px;
    width: 100%;
    line-height: 16px;
    font-weight: 600;
    // position: absolute;
    // bottom: 5px;
  }
}
.dnaContainerBox {
  padding: 55px 15px 0 15px;
  max-width: 1140px;
  margin: auto;
}
.dnaWrapperBox {
  &:first-child {
    width: 100%;
  }
  width: 100%;
  border: 1px solid #ddd;
  margin: 10px;
  border-radius: 10px;
  &.threeBlock1 {
    width: 41.66%;
  }
  &.threeBlock2 {
    width: 16.66%;
  }
}
.dnaImageSwipe {
  .slick-track {
    margin: auto;
  }
  .sliderMainImage {
    // height: calc(100% - 65px);
    display: flex;
    align-items: center;
  }
  .imageSliderFullBlock {
    text-align: center;
    width: 100%;
    height: 555px;
    display: flex !important;
    iframe,
    img {
      border: none;
      width: 100%;
      height: 100%;
      margin: auto;
      object-fit: contain;
    }
    .magnifier {
      width: 100%;
      margin: 0 auto auto auto;
      img {
        // width: 400px;
        width: 100%;
        border-radius: 17px;
      }
    }
    &.width-100 {
      iframe {
        width: 100%;
        height: 650px;
      }
    }
  }
  .imageSliderSmallBox {
    position: relative;
    height: 80px;
    cursor: pointer;
  }
  .imageSliderSmallBlock {
    padding-right: 5px;
    img,
    iframe {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .imageVideoIcon {
    position: absolute;
    bottom: 3px;
    right: 3px;
    text-align: right;
    background: #000000;
    border-radius: 3px;
    padding: 6px;
    line-height: 1;
    font-size: 12px;
    color: #ffffff;
    display: flex;
    img {
      width: 12px;
      height: 12px;
    }
  }
  .imageVideoTitle {
    font-size: 13px;
    display: block;
    margin-top: 5px;
    color: #454545;
    white-space: pre-wrap;
    text-align: center;
  }
  .sliderSmallImage {
    padding: 10px 24px;
    .slick-slide {
      // width: 90px !important;
      height: auto;
      &.slick-current {
        .imageSliderSmallBlock {
          .imageSliderSmallBox {
            border: 2px solid @themeColor;
            padding: 2px;
          }
        }
      }
    }
  }
}
.threeBlock1 {
  .sliderSmallImage {
    .slick-slide {
      &.slick-cloned {
        display: none;
      }
    }
  }
}
.dnaLeftBox {
  // border-bottom: 1px solid #ddd;
  // overflow: hidden;
  // display: flex;
  // min-height: calc(100vh - 128px);
  .dnaLeftBoxImage {
    border-right: 1px solid #ddd;
  }
}
.dnaLeftBoxImage {
  width: 100%;
  // float: left;
  // padding-bottom: 20px;
  // .dnaImageSwipe {
  .imageSliderFullBlock {
    height: 55vh !important;
    width: 555px !important;
    margin: auto;
    // height: auto;
    .magnifier {
      height: 100% !important;
    }
    img {
      width: 100% !important;
      height: 100%;
    }
  }
  // }
}
.dnaImageTop {
  padding: 11px;
  font-size: 15px;
  // background:@header-strip-color;
  background-color: @themeSecondColor;
  // margin-bottom: 10px;
  // background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  border-radius: 10px 10px 0 0;
  margin: 0 0 10px 0;
  span {
    display: block;
    font-weight: 600;
    color: #fff;
    text-align: center;
  }
  &.mainBox {
    background: @themeSecondColor;
    margin: 0 0 10px 0;
    border: none;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    span {
      color: #fff;
    }
  }
}
.dnaLeftRightDetil {
  width: 100%;
  margin-bottom: 20px;
  // float: left;
  position: relative;
  align-items: flex-start;
  // border-top: 1px solid #ddd;
  .dnaDetailBlock {
    width: 33.33%;
    border-right: 1px solid #ddd;
    padding: 0 10px;
    align-items: flex-start;
    .mainHeadingTitle {
      background: #f5f5f5;
      width: 100%;
      font-size: 14px;
      margin-bottom: 10px;
      line-height: 16px;
      padding: 10px;
      display: flex;
      align-items: center;
    }
  }
}
.dnaDetailBlock {
  padding: 10px;
  .detail {
    // padding: 5px 8px;
    width: 100%;
    display: flex;
    span {
      color: #000;
      display: block;
      border: 1px solid #ddd;
      padding: 3px 8px;
      border-bottom: none;
      &:first-child {
        width: 124px;
        // font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.5px;
        // text-transform: uppercase;
      }
      &:last-child {
        font-size: 14px;
        font-weight: 500;
        word-break: break-word;
        width: calc(~'100% - 124px');
      }
      &:nth-child(odd) {
        border-right: none;
      }
    }
    &:last-child {
      span {
        border-bottom: 1px solid #ddd;
      }
    }
    &.width-100 {
      span {
        width: auto;
      }
    }
  }
}
.dnaJourenySticky {
  position: sticky;
  top: 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 20px;
}
.diamondJourneyBlock {
  display: flex;
  justify-content: space-between;
  align-items: normal;
  flex-wrap: wrap;
  padding: 10px;
  // max-height: calc(100vh - 56px);
  // overflow: auto;
  .diamondJourneyItem {
    margin-bottom: 20px;
    width: calc(~'25% - 10px');
    margin: 5px;
    height: auto;
    text-align: center;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 10px;
    .diamondJourneyTitle {
      margin-bottom: 10px;
      span {
        background-color: #fafafa;
        color: #000;
        font-weight: 600;
        font-size: 16px;
      }
    }
    .diamondJourneyImage {
      margin: auto;
      width: 100%;
      height: 200px;
      border-radius: 10px;
      .magnifier {
        height: 100% !important;
      }
      img,
      iframe {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        border: none;
        object-fit: cover;
      }
    }
  }
}
.dnaDetailShareOption {
  display: flex;
  position: absolute;
  right: 5px;
  top: 4px;
}
.dnaShareOption {
  width: auto;
  height: 35px;
  background-color: @themeColor;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  margin: 0 2px;
  img {
    margin: auto;
    width: 16px;
    height: 16px;
  }
  span {
    margin-left: 10px;
    // color:@themeColor !important;
  }
}
.dnaWrapperBox .slick-prev,
.dnaWrapperBox .slick-next {
  width: 34px;
  height: 34px;
  border: 1px solid #ddd;
  border-radius: 100%;
  left: 15px;
  cursor: pointer;
  z-index: 1;
  background-color: #fff !important;
}
.dnaWrapperBox .slick-next {
  left: auto;
  right: 15px;
}
.dnaWrapperBox .sliderSmallImage .slick-prev,
.dnaWrapperBox .sliderSmallImage .slick-next {
  top: 50px;
}
.dnaWrapperBox .slick-prev::before,
.dnaWrapperBox .slick-next::before {
  content: '';
  background-image: url('../assets/svg/left-arrow.svg');
  width: 14px;
  height: 14px;
  background-size: 100%;
  opacity: 1;
  display: block;
  margin: auto;
}
.dnaWrapperBox .slick-next::before {
  transform: rotate(180deg);
}
.dnaWrapperBox .slick-prev:hover {
  background-color: #fff;
}
.similarStoneScroll {
  height: 670px;
  overflow: auto;
}
.similarStoneBlock {
  .similarStoneBlockItem {
    width: 100%;
    padding: 10px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    span {
      display: block;
      text-align: center;
      margin-top: 10px;
      color: #000;
    }
  }
}
.no-msg {
  min-height: 540px;
  width: 100%;
  font-size: 18px;
  color: #000;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
@media (max-width: 1400px) {
  // .diamondJourneyBlock{
  //     .diamondJourneyItem{
  //         width:100%;
  //         .diamondJourneyImage{
  //             img{
  //                 width: auto;
  //             }
  //         }
  //     }
  // }
  // .dnaImageSwipe{
  //     .imageSliderFullBlock{
  //         img,iframe{
  //             width: 420px;
  //             height: 420px;
  //         }
  //         &.width-100{
  //             iframe{
  //                 height: 450px !important;
  //             }
  //         }
  //     }
  // }
}
@media (min-width: 768px) and (max-width: 991px) {
  .dnaContainerBox {
    padding: 90px 15px 0 15px;
    .dnaWrapper {
      .dnaWrapperBox {
        &.threeBlock1 {
          width: 40%;
        }
        &.threeBlock2 {
          width: 20%;
        }
      }
      .dnaImageSwipe {
        .imageSliderFullBlock {
          height: 420px;
          .magnifier {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .similarStoneScroll {
    height: 540px;
  }
}

@media (max-width: 767px) {
  .dnaContainerBox {
    padding: 90px 15px 0 15px;
    .dnaWrapper {
      flex-wrap: wrap;
      .dnaWrapperBox {
        width: 100%;
        margin: 10px 0;
      }
      .dnaImageSwipe {
        .imageSliderFullBlock {
          .magnifier {
            img {
              width: 100%;
            }
          }
        }
        .sliderTwoImageBlock {
          .imageSliderFullBlock {
            height: auto;
          }
        }
      }
    }
    .similarStoneScroll {
      height: 470px;
      .no-msg {
        min-height: 470px;
      }
    }
    .dnaWrapperBox {
      margin: 0;
    }
    .dnaLeftRightDetil {
      .dnaDetailBlock {
        width: 100%;
        margin-bottom: 15px;
        border-right: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .dnaLeftBoxImage {
      .imageSliderFullBlock {
        img {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
    .diamondJourneyBlock {
      flex-wrap: wrap;
      .diamondJourneyItem {
        width: 100%;
      }
    }
    .dnaJourenySticky {
      iframe {
        height: 400px;
      }
    }
  }
  .dnaImageTop {
    &.mainBox {
      text-align: center;
    }
    .dnaDetailShareOption {
      position: relative;
      margin: auto;
      right: 0;
      left: 0;
      width: max-content;
    }
  }
  .qrCodeOption {
    display: none;
  }
}
.viewImageBlock {
  align-items: flex-start;
  .viewImageBlockLeft {
    width: 25%;
    padding: 10px;
    .dnaWrapperBox {
      margin: 0;
    }
    p {
      padding: 0 10px;
      color: #000;
      font-weight: 600;
    }
  }
  &.centeBlock {
    .viewImageBlockLeft {
      width: 30%;
    }
  }
  .viewImageBlockRight {
    width: calc(~'37.5% - 20px');
    display: flex;
    margin: 10px;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    .magnifier {
      text-align: center;
      max-width: 600px;
      max-height: 600px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: auto;
    }
    &.halfBlock {
      width: 40%;
    }
    &.PlottingImages {
      .magnifier {
        max-width: 750px;
        max-height: 500px;
      }
      img {
        object-fit: contain;
      }
    }
    &.HeartImages,
    &.ArrowImages {
      justify-content: center;
      .magnifier {
        max-width: 500px;
        max-height: 500px;
      }
    }
  }
}
.viewImageDetail {
  width: 100%;
  display: flex;
  padding: 0 10px;
  margin: 20px auto auto auto;
  span {
    width: 60px;
    color: #000;
    font-weight: 600;
  }
  p {
    width: calc(~'100% - 60px');
  }
}

@media (max-width: 767px) {
  .viewImageBlock {
    flex-wrap: wrap;
    .viewImageBlockLeft {
      width: 100% !important;
    }
    .viewImageBlockRight {
      width: 100% !important;
    }
  }
}
