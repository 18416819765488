@import 'variables';

.ant-drawer-right .ant-drawer-content-wrapper {
  width: 370px!important;
  padding: 15px;
  background: #fff;
}
.ant-drawer-body {
  padding: 0 !important;
}
.gx-logo img {
  height: 40px;
}

.gx-header-horizontal-top {
  display: none;
}

.gx-header-horizontal-dark:before {
  display: none;
}

.react-grid-HeaderCell {
  font-weight: inherit;
}
.signinlogo img {
  width: 125px;
}

@iterations: 50;

.m-loop (@i) when (@i > 0) {
  .m-l-@{i} {
    margin-left: ~'@{i}px';
  }

  .m-r-@{i} {
    margin-right: ~'@{i}px';
  }

  .m-t-@{i} {
    margin-top: ~'@{i}px';
  }

  .m-b-@{i} {
    margin-bottom: ~'@{i}px';
  }

  .m-h-@{i} {
    margin: ~'0px @{i}px';
  }

  .m-v-@{i} {
    margin: ~'@{i}px 0px';
  }

  .m-loop(@i - 5);
}

.m-v-15 {
  margin: 15px 0px;
}

.p-l-20 {
  padding-left: 15px;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #efefef;
}

.inlineRow {
  display: inline-block;
  width: 50%;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}

.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

// #testMenu {
//   background-color: #fff;
//   border-color: #fff;

// }
.ant-menu-dark .ant-menu-item-selected {
  color: #000 !important;
}

#logo_name {
  margin-left: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 66px;
}
.gx-main-content-wrapper {
  padding: 10px 10px 0;
  flex: 1 1;
}
.gx-sidebar-content {
  height: 900%;
}
.gx-inside-header-horizontal .gx-header-horizontal-main {
  padding: 28px;
}
.gx-layout-sider-header {
  height: 56px;
  padding: 5px;
}
.gx-layout-sider-header .gx-site-logo {
  text-align: center;
}
.gx-layout-sider-header .gx-site-logo img {
  max-width: 75%;
  margin: auto;
}
.gx-header-horizontal-dark .gx-header-horizontal-nav {
  padding-right: 20px;
}
.gx-layout-sider-scrollbar {
  height: 100vh !important;
}
.gx-module-box-content {
  height: 100%;
}
.ant-table-pagination.ant-pagination {
  position: fixed;
  right: 15px;
  bottom: 15px;
}
.custom-pagination .ant-select-dropdown {
  top: auto !important;
  bottom: 0;
}
// .gx-main-content-wrapper {
//   height: calc(~'100vh - 70px');
// }
// .gx-module-content-scroll {
//   height: 90vh !important;
// }
// .ant-spin-nested-loading .ant-table {
//   height: 70vh;
// }
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.align-arrow label {
  width: 28px;
  height: 28px;
  text-align: center;
  background: #fff;
  float: left;
  padding: 2px;
  border: 1px groove black;
}

.menuName-1 {
  color: #283895;
}
.menuName-2 {
  color: #283895;
}
.menuName-3 {
  color: #283895;
}
.menuName-1:hover {
  color: red;
}
.menuName-2:hover {
  color: red;
}
.menuName-3:hover {
  color: red;
}

.Parcel-price,
.Parcel-price tr,
.Parcel-price th,
.Parcel-price td {
  border: 1px solid black;

  padding: 8px;
}
.Parcel-price th {
  text-align: center;
}
.Parcel-price {
  width: 100%;
}
.parcel-table-width {
  width: calc(~'50% - 20px');
  float: left;
  margin: 10px 10px 10px 10px;
}
.parcel-table-width td input {
  width: 100%;
  border: none;
}
.parcel-table-width td input:focus {
  outline: none;
}
.parcel-tabel-height {
  display: flex;
  flex-wrap: wrap;
}

.react-grid-HeaderCell {
  font-weight: inherit;
}

.ant-table-row input {
  width: 100%;
}

.gx-module-content-scroll > div:first-child {
  overflow: visible !important;
}
.pagination-height .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  max-height: calc(~'100vh - 228px') !important;
  width: calc(~'100% - 0px');
}
.pagination-height
  .hospitality-orders
  .ant-table-fixed-header
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body,
.pagination-height .upload-history .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body,
.pagination-height .user-page .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body,
.pagination-height
  .transport-orders
  .ant-table-fixed-header
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body {
  max-height: calc(~'100vh - 290px') !important;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  max-height: calc(~'100vh - 190px') !important;
}
.gridcolumnHeight .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  max-height: calc(100vh - 10px) !important;
}

.permission-table .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  max-height: calc(100vh - 30px) !important;
}
.permission-table {
  padding-bottom: 15px;
}
.Excel-Model .ant-modal-content {
  width: 70%;
}
.Excel-Model Parcel-price,
.Excel-Model tr,
.Excel-Model th,
.Excel-Model td {
  border: none !important;
}
.Excel-Model td {
  padding: 0 8px;
}
.ant-upload-list.ant-upload-list-text {
  display: none;
}

.pagination .d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.j-space-between {
  justify-content: space-between !important;
}
.total-count-lbl {
  padding: 0 10px;
}
.diamond-stock-table .ant-pagination > li {
  margin-top: 10px;
}
.j-flex-end {
  justify-content: flex-end !important;
}
.account-detail .ant-avatar {
  display: block;
  float: left;
  width: 180px !important;
  height: 180px !important;
  margin-right: 15px;
}
.account-content h2 {
  margin-right: 10px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.account-content h3 {
  margin-bottom: 0;
}
.account-content {
  display: block;
  overflow: hidden;
}
.account-content p {
  margin-bottom: 5px;
}
.account-content p span:first-child {
  font-size: 16px;
  margin-right: 5px;
  font-weight: 500;
}
.account-content p span:last-child {
  font-size: 14px;
}
.ant-card-body {
  padding: 24px;
  zoom: 1;
}
.mainDashboard .ant-card-body {
  padding: 40px;
  zoom: 1;
}
.gx-pro-contact-list:not(:last-child) {
  margin-bottom: 1rem;
}
.gx-align-items-center {
  -webkit-align-items: center !important;
  -ms-align-items: center !important;
  align-items: center !important;
}
.gx-flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.gx-media {
  width: 33.33%;
  float: left;
  min-height: 42px;
  display: flex;
  align-items: center !important;
}
.Account-details.anticon svg {
  width: 30px;
  height: 30px;
  fill: #283895 !important;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #283895 !important;
}
.ant-tabs-ink-bar {
  background-color: #283895 !important;
}
.account-content .gx-fs-sm {
  font-size: 14px;
  margin-bottom: 2px !important;
  display: block;
}
.account-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.account-content .ant-card-body {
  padding: 15px 0;
  display: flex;
  flex-wrap: wrap;
}
.gx-module-box-content {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.account-main-block {
  height: calc(100vh - 28px);
}
.gx-module-box.pagination-height {
  //padding-bottom: 60px;
  height: calc(~'100% - 60px');
}
.account-main-wrapper .ant-table-thead > tr > th,
.account-main-wrapper .ant-table-tbody > tr > td {
  min-width: 98px;
  width: 98px;
  text-align: left;
  max-width: 98px;
}
.account-main-wrapper .ant-table-thead > tr > th:first-child,
.account-main-wrapper .ant-table-tbody > tr > td:first-child {
  min-width: 40px;
  max-width: 40px;
  padding: 15px 0;
  width: 40px;
}
.account-main-wrapper .ant-table-thead > tr > th:nth-child(2),
.account-main-wrapper .ant-table-tbody > tr > td:nth-child(2) {
  min-width: 30px;
  max-width: 30px;
  padding: 15px 0;
  width: 30px;
}
.account-main-wrapper .hospitality-orders .ant-table-thead > tr > th:nth-child(2),
.account-main-wrapper .hospitality-orders .ant-table-tbody > tr > td:nth-child(2) {
  max-width: 40px;
}

//feedbackwrapper classss
.account-main-wrapper-feedback {
  height: calc(100%);
}
.account-main-wrapper-feedback .ant-table-thead > tr > th,
.account-main-wrapper-feedback .ant-table-tbody > tr > td {
  text-align: left;
}
.account-main-wrapper-feedback .ant-table-thead > tr > th:nth-child(n + 6),
.account-main-wrapper-feedback .ant-table-tbody > tr > td:nth-child(n + 6) {
  min-width: 118px;
  max-width: 118px;
  padding: 15px 5px;
  width: 118px;
}
//sr no.
.account-main-wrapper-feedback .ant-table-thead > tr > th:nth-child(1),
.account-main-wrapper-feedback .ant-table-tbody > tr > td:nth-child(1) {
  min-width: 50px;
  max-width: 50px;
  padding: 15px 5px;
  width: 50px;
}
//layout
.account-main-wrapper-feedback .ant-table-thead > tr > th:nth-child(2),
.account-main-wrapper-feedback .ant-table-tbody > tr > td:nth-child(2) {
  min-width: 60px;
  max-width: 60px;
  padding: 15px 5px;
  width: 60px;
}
//visual appearance
.account-main-wrapper-feedback .ant-table-thead > tr > th:nth-child(3),
.account-main-wrapper-feedback .ant-table-tbody > tr > td:nth-child(3) {
  min-width: 132px; //135
  max-width: 132px;
  padding: 15px 5px;
  width: 132px;
}
//improvement
.account-main-wrapper-feedback .ant-table-thead > tr > th:nth-child(4),
.account-main-wrapper-feedback .ant-table-tbody > tr > td:nth-child(4) {
  min-width: 180px;
  max-width: 180px;
  padding: 15px 5px;
  width: 180px;
}
//revert back
.account-main-wrapper-feedback .ant-table-thead > tr > th:nth-child(5),
.account-main-wrapper-feedback .ant-table-tbody > tr > td:nth-child(5) {
  min-width: 180px;
  max-width: 180px;
  padding: 15px 5px;
  width: 180px;
}

.display-none {
  display: none;
}

//feedbackwrapper over

.cabin-table td span i {
  margin: 0 10px;
}
.display-flex {
  display: flex;
}
// .cabin-modal .add-cabin-block > span {
//   width: 100px;
//   min-width: 100px;
//   margin-right: 15px;
//   text-align: right;
// }
.cabin-modal .add-cabin-block {
  margin-bottom: 15px;
  align-items: center;
}
.cabin-modal .add-cabin-block button {
  margin-bottom: 0;
}

.ant-form-item-required::before {
  margin-right: 0 !important;
  position: absolute;
  right: -10px;
}
.ant-modal-footer .ant-btn:not(:last-child) {
  margin-right: 0 !important;
}
.ant-btn {
  margin-bottom: 0;
}
.ant-btn-sm {
  line-height: 22px !important;
}
.j-flex-end {
  justify-content: flex-end;
}

// Appointment
.slot-block span {
  display: block;
}
.mb-8 {
  margin-bottom: 8px;
}
.slot-date-block,
.slot-count {
  width: 50%;
}
.pl-15 {
  padding-left: 15px;
}
.slot-block {
  margin-bottom: 20px;
}
.slot-modal {
  width: 60% !important;
}
.hkSidebarMenu .ant-menu {
  background: transparent;
}
.hkSidebarMenu .ant-menu-inline > .ant-menu-item a {
  padding: 10px 0px !important;
  height: 50px;
  line-height: 30px;
}
.hkSidebarMenu .ant-menu-inline > .ant-menu-item {
  height: auto;
  line-height: inherit;
}

.hkSidebarMenu .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 50px;
  line-height: 50px;
}
.hkSidebarMenu.ant-layout-sider .ant-menu-inline {
  padding: 20px 0px;
  border: none;
}
.hkSidebarMenu .ant-menu-item > a,
.hkSidebarMenu .ant-menu {
  color: #ffffff;
}
.hkSidebarMenu .ant-menu-item > a:hover,
.hkSidebarMenu .ant-menu-submenu-title:hover {
  color: rgba(255, 255, 255, 0.7);
}

.hkSidebarMenu .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.hkSidebarMenu .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.hkSidebarMenu .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.hkSidebarMenu .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.hkSidebarMenu .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.hkSidebarMenu .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.hkSidebarMenu .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.hkSidebarMenu .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
}

.hkSidebarMenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: #9999ff;
}

.hkSidebarMenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a {
  color: #fff !important;
}

.hkSidebarMenu .ant-menu-submenu > .ant-menu {
  background-color: rgba(3, 143, 221, 0.29) !important;
  border-radius: 0;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 40px !important;
}

.gx-header-horizontal-nav,
.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #283895 !important;
}

.hkSidebarMenu .ant-menu-submenu-selected .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.hkSidebarMenu .ant-menu-submenu-selected .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  background: linear-gradient(to right, #283895, #283895) !important;
}

.hkSidebarMenu .ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #283895;
}

.gx-module-box-header {
  padding: 10px 15px !important;
}

.ant-btn-sm {
  border-radius: 3px !important;
  height: 32px;
  padding: 0 10px;
  letter-spacing: 0.9px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 10px 10px !important;
}

.gx-module-box-content {
  background-color: #ffffff;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.gx-module-content-scroll {
  border: 1px solid #e8e8e8;
}
.account-main-wrapper .ant-table-thead > tr > th:first-child,
.account-main-wrapper .ant-table-tbody > tr > td:first-child {
  padding-left: 15px !important;
  padding-right: 5px !important;
}

.myiframe {
  height: 560px;
  width: 100%;
  border: 0;
}
.footer-content {
  padding: 12px 15px 5px 15px;
  //position: fixed;
  bottom: 0;
  width: 100%;
  //width: calc(~"100% - 248px");
  z-index: 3;
  background: #f0f4f9 !important;
}
.footer-content .ant-pagination {
  //padding-right: 240px;
  display: flex;
  position: relative;
  justify-content: flex-end;
}
.ant-pagination-options {
  position: absolute;
  left: 0;
  margin-left: 0;
}
.account-main-wrapper {
  height: calc(100%);
}
.gx-module-content-scroll {
  height: 100% !important;
}
.height-100 {
  height: 100%;
}
.height-100vh {
  height: 100vh;
}
.height-auto{
  height: auto !important;
}
.ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.permission-table table tr th,
.permission-table table tr td {
  text-align: center;
}
.active-checkbox {
  display: flex;
  /* align-items: center; */
  margin-top: 20px;
}
.active-checkbox .ant-form-item {
  margin-bottom: 0;
}
.sub-master {
  overflow: auto !important;
}
// .sub-master-box {
//   padding: 20px 15px !important;
// }
.user-role {
  padding: 10px 15px;
}
.user-role-left {
  margin-right: 20px;
}
user-role-left label {
  margin-right: 8px;
}
.ant-checkbox-inner .permission {
  width: 10px;
  height: 10px;
}
.permission-tabel {
  padding: 0;
}
.permission-tabel table tr th {
  white-space: nowrap;
}
.permission-tabel table tr th,
.permission-tabel table tr td {
  min-width: 100px;
  text-align: left;
}
.permission-tabel table tr th:first-child,
.permission-tabel table tr td:first-child {
  min-width: 160px;
  text-align: left;
}

// -----------------Slot----------------
.slot-label {
  width: 12%;
  display: block;
}
.slot-detail {
  width: 88%;
}
.slot-table-header span {
  font-weight: bold;
}
.slot-count-week {
  width: 30px;
  height: 30px;
  background-color: rgb(158, 166, 212);
  color: #fff;
  display: block;
  line-height: 30px;
  text-align: center;
  margin-right: 18px;
  position: relative;
  border-radius: 2px 0 0 2px;
}
.slot-count-week:after {
  content: '';
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid rgb(158, 166, 212);
  position: absolute;
  right: -15px;
}
.slot-detail .ant-tag,
.slot-count-list .ant-tag {
  height: 30px;
  line-height: 30px;
  margin: 2px 2px;
}
.slot-detail {
  align-items: center;
}
.appointment-table-box .ant-list-item {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.cabin-select {
  margin: 0 10px;
}
.slot-top-block .ant-row {
  margin: 0 !important;
}
.slot-top-block {
  align-items: center;
}
.slot-title-btn {
  margin-bottom: 20px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.slot-list-tags {
  width: calc(100% - 5px);
  height: 36px;
  overflow: hidden;
  position: relative;
  padding-right: 90px;
}
.slot-list-tags.active {
  height: auto;
}
.slot-view-more-btn,
.slot-hide-btn {
  position: absolute;
  top: 10px;
  right: 12px;
  cursor: pointer;
  color: #283895;
}
.slot-hide-btn {
  color: #283895;
  cursor: pointer;
  margin-left: 10px;
}
.appointment-table-box .ant-spin-container .ant-list-item {
  min-height: 40px;
}
.appointment-table-box .ant-spin-container .ant-list-item:nth-child(even) {
  background-color: rgba(40, 56, 149, 0.04);
}
.slot-table-header .ant-badge-count {
  background-color: rgb(158, 166, 212) !important;
  margin-top: 5px;
  margin-left: 5px;
}
.tag-edit {
  margin: 0 5px 0 15px;
  cursor: pointer;
}
.slot-list-tags .anticon-close svg {
  fill: #f05050;
  width: 16px;
  height: 16px;
  margin: -3px 0;
}
.slot-detail .ant-tag {
  display: flex;
  align-items: center;
  float: left;
}

.parcel-table-scroll .gx-module-content-scroll > div:first-child {
  overflow: auto !important;
}
.parcel-nodata {
  width: 100%;
  text-align: center;
  margin-top: 100px;
}
//Dashboard icon
.hkSidebarMenu .gx-sidebar-content ul li a,
.hkSidebarMenu .gx-sidebar-content ul li .sub-menu-dropdown {
  display: block;
  align-items: center;
}
.hkSidebarMenu .gx-sidebar-content ul li a .vertical-menu,
.hkSidebarMenu .gx-sidebar-content ul li .sub-menu-dropdown .vertical-menu {
  color: #038fdd;
}
.hkSidebarMenu .gx-sidebar-content ul.ant-menu-vertical li a,
.hkSidebarMenu .gx-sidebar-content ul.ant-menu-vertical li .sub-menu-dropdown {
  justify-content: center;
  display: block;
  text-align: center;
}
.hkSidebarMenu.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item,
.hkSidebarMenu.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 2px !important;
  margin-bottom: 15px;
  height: auto;
}
.hkSidebarMenu .gx-sidebar-content ul li a svg,
.hkSidebarMenu .gx-sidebar-content ul li .sub-menu-dropdown svg {
  display: block;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  fill: #038fdd;
}
.hkSidebarMenu .ant-menu-submenu-selected .ant-menu-submenu-title,
.hkSidebarMenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}
.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-submenu-selected .sub-menu-dropdown .vertical-menu,
.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-submenu-active .sub-menu-dropdown .vertical-menu,
.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-item-selected .vertical-menu,
.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-item-active .vertical-menu {
  color: #fff;
}
.hkSidebarMenu
  .gx-sidebar-content
  ul
  li.ant-menu-submenu-selected
  .sub-menu-dropdown
  svg,
.hkSidebarMenu
  .gx-sidebar-content
  ul
  li.ant-menu-submenu-active
  .sub-menu-dropdown
  svg,
.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-item-selected svg,
// .hkSidebarMenu .gx-sidebar-content ul li.ant-menu-item-active svg {
//   fill: #fff;
// }
.hkSidebarMenu .gx-sidebar-content ul.ant-menu-vertical .vertical-menu {
  display: block;
  font-size: 12px;
  line-height: 24px;
  white-space: normal;
}
.hkSidebarMenu .gx-sidebar-content ul.ant-menu-vertical li a svg,
.hkSidebarMenu .gx-sidebar-content ul.ant-menu-vertical li .sub-menu-dropdown svg {
  margin: 5px auto 5px auto;
  width: 22px;
  height: 22px;
}
.hkSidebarMenu .gx-sidebar-content ul li .ant-menu-submenu-arrow {
  right: 10px !important;
  left: auto !important;
}
.hkSidebarMenu .gx-sidebar-content ul li .ant-menu-submenu-arrow:before,
.hkSidebarMenu .gx-sidebar-content ul li .ant-menu-submenu-arrow:after {
  background-image: linear-gradient(to right, #038fdd, #038fdd);
}
.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-submenu-selected .ant-menu-submenu-arrow:before,
.hkSidebarMenu .gx-sidebar-content ul li.ant-menu-submenu-selected .ant-menu-submenu-arrow:after,
.hkSidebarMenu
  .gx-sidebar-content
  ul
  li.ant-menu-submenu-selected
  .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow:after,
.hkSidebarMenu
  .gx-sidebar-content
  ul
  li.ant-menu-submenu-selected
  .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow:before {
  background-image: linear-gradient(to right, #fff, #fff);
}
.ant-layout-sider {
  width: 90px !important;
  position: relative;
  z-index: 1;
}
.hkSidebarMenu.ant-layout-sider .ant-menu-item,
.hkSidebarMenu.ant-layout-sider .ant-menu-submenu-title {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.gx-layout-sider-header .gx-linebar {
  left: 12px;
  top: 8px;
}
.ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
  height: calc(~'100vh - 56px') !important;
}
.ant-layout-sider-collapsed,
.ant-menu-inline-collapsed {
  width: 90px !important;
}

.gx-module-box-header {
  min-height: auto;
  border-bottom: none !important;
}
.gx-main-content-wrapper .ant-btn:not(:last-child) {
  margin-right: 0;
}
.ant-table-thead > tr {
  background: #f0f4f9 !important;
}
.ant-layout.gx-app-layout > .ant-layout {
  width: calc(~'100% - 78px');
}
.anticon-edit svg {
  fill: #283895;
}
.anticon-delete svg {
  fill: #f44336;
}
.ant-list-bordered .ant-list-item.gx-bg-primary {
  color: #fff;
}

.ant-select-selection,
.ant-input {
  border-radius: 4px !important;
}
.ant-select.ant-select-enabled .ant-select-selection-selected-value,
.ant-select.ant-select-enabled .ant-select-dropdown-menu-item {
  display: flex !important;
  align-items: center;
}
.ant-tooltip-content .ant-tooltip-inner svg {
  fill: #fff;
  display: block;
  margin-right: 5px;
}
.ant-tooltip-content .ant-tooltip-inner a {
  display: flex;
  align-items: center;
}
.grid-column-table td input {
  color: #545454;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
  height: 36px;
}
.diamond-stock-page {
  padding-bottom: 15px;
}
.diamond-stock-page .gx-module-box-content {
  padding-bottom: 0;
}
.mb-15 {
  margin-bottom: 15px;
}
.notification-select-dropdown {
  width: 100%;
}
.add-notificaion-box span {
  margin-bottom: 5px;
  display: block;
}
.gx-module-box-content.pagination-height {
  padding: 0;
}
.ant-table-tbody > tr > td:first-child {
  padding-left: 10px !important;
}

.featuredstone.account-main-wrapper .ant-table-thead > tr > th:nth-child(2),
.featuredstone.account-main-wrapper .ant-table-tbody > tr > td:nth-child(2) {
  min-width: 80px !important;
  max-width: 80px !important;
  width: 80px !important;
}
.feature-stone-popup label {
  width: 100%;
  margin-bottom: 8px;
}
.feature-stone-popup input {
  width: 100%;
}
.justify-content-center {
  justify-content: center !important;
}
.mt-10 {
  margin-top: 10px;
}
.mb-20 {
  margin-bottom: 20px !important;
}
// MODAL FOOTER BTN
.ant-modal-footer .ant-btn-primary {
  position: absolute;
  right: 105px;
}
.cabin-select-option {
  width: calc(~'100% - 100px');
}
.slots-cabin {
  align-items: center;
}
.slots-cabin label {
  border: 1px solid rgba(40, 56, 149, 0.31);
  color: #283895;
  /* font-weight: bold; */
  padding: 8px 12px;
  border-radius: 6px 0 0 6px;
  // border-right: none;
  background: rgba(40, 56, 149, 0.31);
}
.slots-cabin .cabin-select,
.slots-cabin .ant-select-selection {
  margin-left: 0;
  border-radius: 0px 6px 6px 0px;
}

.mt-40 {
  margin-top: 40px !important;
}

// banner table
.banner-table tr th:nth-child(4),
.banner-table .ant-table-tbody tr td:nth-child(4) {
  width: 50px;
  min-width: 50px;
}
#BannerUpsertForm_fromdateTime,
#BannerUpsertForm_toDateTime {
  min-width: 100% !important;
}
.banner-form-box {
  height: calc(~'100% - 66px');
}

#EventUpsertForm_fromdateTime,
#EventUpsertForm_toDateTime {
  min-width: 100% !important;
}

.event-table-block tr th:nth-child(2),
.event-table-block tr td:nth-child(2) {
  min-width: 50px !important;
  max-width: 50px !important;
  width: 50px !important;
}
.event-table-block tr th:nth-child(4),
.event-table-block tr td:nth-child(4),
.event-table-block tr th:nth-child(5),
.event-table-block tr td:nth-child(5) {
  min-width: 80px !important;
  max-width: 80px !important;
  width: 80px !important;
}

.page-track-heder-tag .ant-select-selection__rendered {
  height: 35px !important;
  // overflow-x: scroll;
  // overflow-y: hidden;
  display: -webkit-box;
}

.page-track-heder-tag .ant-select-selection__rendered::-webkit-scrollbar {
  background-color: transparent !important;
}

.page-track-heder-tag .ant-select-selection__rendered ul {
  display: -webkit-box;
  overflow: hidden;
}
.oder-setting-block {
  padding: 15px;
}
.oder-setting-block label.order-label {
  width: 100%;
  margin-bottom: 20px;
  display: block;
  font-size: 16px;
}
.order-checkbox {
  display: block;
  margin-bottom: 15px;
}
.order-note-label {
  margin-bottom: 5px;
  display: block;
  margin-right: 10px;
  white-space: nowrap;
}
.oder-setting-block textarea {
  width: 100%;
  height: 100px;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  display: block;
}
.ant-table {
  color: #000 !important;
}
.dropdown-page-tracking {
  width: 100%;
  float: none;
  display: block;
}
.width-100 {
  width: 100% !important;
}
.blackcolor {
  color: #333 !important;
}
.account-main-wrapper .page-tracking .ant-table-thead > tr > th:nth-child(2),
.account-main-wrapper .page-tracking .ant-table-tbody > tr > td:nth-child(2) {
  padding: 15px !important;
}
.enquiry-ok-btn {
  position: relative !important;
  right: 0 !important;
}
.ant-table-column-title {
  color: #333 !important;
}
.ant-form-item-label > label {
  color: #333 !important;
}
.gx-mini-sidebar .ant-menu-item > a {
  color: #333 !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.m-0 {
  margin: 0 !important;
}
.accountBold b {
  font-weight: 550;
  color: #262626 !important;
}
.width-80 {
  width: 80%;
}
.ant-table-expanded-row-level-1 .ant-table-wrapper {
  margin: 0 !important;
}
.ant-table-row-expand-icon-cell,
.ant-table-expand-icon-th {
  display: none;
}
.hospitality-label {
  font-size: 16px;
  margin-bottom: 10px;
}
.enquiry-detail-tab .react-grid-Grid {
  min-height: calc(~'100vh - 248px') !important;
}

// .oder-setting-block{
//   width: calc(~"50% - 20px");
// }
.general-divider {
  top: 0 !important;
}
.order-label {
  font-weight: bold;
}
.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-bottom: 0;
}
.ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
  display: flex;
}
.ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .ant-btn {
  order: 2;
  margin-right: 0;
  margin-left: 10px;
  color: #ff4d4f;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-modal-confirm-body-wrapper .ant-modal-confirm-btns button.ant-btn:first-child:hover {
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  color: #f5f5f5 !important;
}
// .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .ant-btn .hover {
//   color: #d9d9d9;
//   background-color: #f5222d !important;

// }
.ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .ant-btn-primary {
  order: 1;
}

.required-green .ant-form-item-required::before {
  color: green;
}
.required-red {
  color: red;
}
.required-green {
  color: green;
}
.mb-5 {
  margin-bottom: 5px;
}
.display-block {
  display: block;
}
.dashboard-table {
  padding: 18px 16px;
}
.dashboard-table .ant-table-wrapper {
  border: 1px solid #e8e8e8;
}
.dashboard-table .ant-table-pagination.ant-pagination {
  position: relative;
  bottom: 0;
}
.justify-space-between {
  justify-content: space-between;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.reset-password-box .form-control label {
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
}
.reset-password-box .form-control input {
  height: 36px;
  color: #545454;
  background-color: #fff;
  width: 100%;
  border-radius: 4px !important;
  box-shadow: none;
  border: 1px solid #d9d9d9;
  padding: 10px;
}
.reset-password-box.mb-20 {
  margin-bottom: 20px;
}
.multiple-box .ant-upload-list-picture {
  display: none;
}
.multiple-img-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.multiple-img-wrapper .ant-card {
  width: 150px;
  margin: 0 5px;
}
.multiple-img-block {
  height: 100px;
  margin-bottom: 10px;
}
.multiple-img-block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.multiple-img-wrapper .ant-card-body {
  padding: 10px;
}
.multiple-content-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-histor-wrapper {
  justify-content: space-between;
  box-shadow: 0 11px 35px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 0;
  border-radius: 10px;
  border: 1px solid rgba(41, 56, 148, 0.1);
}
.search-content-block {
  background: #f4f5fa;
  padding: 15px;
  border-radius: 10px 10px 0 0;
}
.search-width {
  width: calc(100% - 121px);
  flex-wrap: wrap;
}
.search-dec-block-wrapper {
  display: flex;
  margin-right: 20px;
  width: auto;
  margin-bottom: 5px;
}

.search-box-date {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.search-date-name {
  color: #283895;
}
.search-block-name h2 {
  font-size: 20px;
  margin-bottom: 0;
  line-height: 22px;
  margin-right: 10px;
  color: #293895;
}
.search-dec-block {
  padding: 15px 15px 10px;
  flex-wrap: wrap;
}
.search-dec-block-wrapper span:first-child {
  font-weight: 700;
  margin-right: 5px;
  white-space: nowrap;
}
.search-dec-block-wrapper span:last-child {
  word-break: break-word;
}

.d-flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.permisiion-lock a {
  margin-left: 10px;
}
.for-events-s {
  overflow: visible !important;
}
.showLoading .loader {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
}
.showLoading .loader img {
  width: 70px;
  height: 70px;
}

.hit-counter-block-wrapper {
  margin-right: 20px;
  width: auto;
  margin-bottom: 5px;
}
.hit-counter-block-wrapper span:first-child {
  font-weight: 700;
  margin-right: 5px;
  white-space: nowrap;
}
.stone-tags .ant-table {
  color: #283895 !important;
  border-color: #283895 !important;
}
.featured-stone-popup {
  display: block;
  width: 100%;
}
.d-block {
  display: block;
}
.featured-stone-popup .ant-tag {
  position: relative;
  min-width: 100px;
  border: 1px solid #222 !important;
  color: #222 !important;
}
.featured-stone-popup .ant-tag .gx-draggable-icon {
  margin-top: 0px;
  float: right;
  margin-right: 12px;
}
.featured-stone-popup .ant-tag .anticon-close {
  position: absolute;
  right: 0;
  height: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 5px;
}
.just-cont {
  justify-content: center !important;
}

.sync-label {
  margin-right: 15px;
  display: block;
  font-size: 16px;
  color: #333 !important;
  font-weight: 700;
}
.pointer {
  cursor: pointer;
}
.set-color {
  color: #545454 !important;
}
.KTY-modal {
  height: 700px;
  width: 700px;
}

.KYC-box {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.basicInfoCanavs {
  width: 180px;
  height: 180px;
}
.basicInfoCanavs canvas {
  width: 100% !important;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #ffffff !important;
  background-color: #096bef !important;
  border-color: #096bef !important;
}
.gx-main-content-wrapper {
  padding: 0;
}
.align-items-start {
  align-items: flex-start !important;
}

// new css
.j-flex-start {
  justify-content: flex-start !important;
}
.marginauto {
  margin: auto !important;
}

.botoomStickyBar {
  position: absolute;
  // bottom: -51px;
  padding: 10px 15px;
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  z-index:100;
  &.positionSticky {
    position: sticky;
    bottom: 0;
    justify-content: space-between;
  }

  .bottomValue {
    margin-right: 5px;
    border-right: 1px solid #e2e2e2;
    padding-right: 5px;
    span {
      font-size: 14px;
      text-transform: uppercase;
      color: #555;
      &:first-child {
        margin-right: 5px;
      }
      &:last-child {
        font-weight: 600;
        color: #000;
      }
    }
    &:last-child {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }
  }
  .diamondDetailActionItem {
    display: flex;
    align-items: center;
    margin: 5px;
    cursor: pointer;
    padding: 6px 10px;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    img {
      width: 20px;
      height: 20px;
      // margin-right: 5px;
    }
    span {
      color: #212431;
      font-weight: 600;
    }
  }
  .bottomStickyButton {
    margin: 0 3px;
    border: 1px solid #ddd;
    padding: 5px 10px;
    position: relative;
    border-radius: 5px;
    span {
      color: #212431;
      font-weight: 600;
    }
    input{
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
    }
  }
  .select-diamonds {
    .ant-select {
      height: 32px;
      .ant-select-selection {
        height: 32px;
        background: @themeLightColor;
        border: 1px solid @light-theme-border;
        .ant-select-selection__rendered {
          line-height: 31px;
          font-size: 12px;
        }
      }
    }
  }
}
.bottomStickyButton {
  margin: 0 3px;
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 5px;
  span {
    color: #212431;
    font-weight: 600;
  }
}
.diamondDetailBottomBox {
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 10px;
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
  background: #fff;
  width: 100%;
  .diamondDetailBottomAction {
    width: 100%;
    height: 53px;
    border-bottom: 1px solid #e2e2e2;
    border-top: none !important;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    .diamondDetailTab {
      border-bottom: none !important;
    }
    .diamondDetailActionItem {
      display: flex;
      align-items: center;
      margin: 5px;
      cursor: pointer;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #e2e2e2;
      img {
        width: 20px;
        height: 20px;
        // margin-right: 5px;
      }
      span {
        color: #212431;
        font-weight: 600;
      }
    }
  }
}
.mt-0 {
  margin-top: 0 !important;
}
.align-items-normal {
  align-items: normal !important;
}
p {
  color: #999;
  line-height: 24px;
}
.mr-10 {
  margin-right: 10px;
}
.ant-radio-checked .ant-radio-inner {
  border-color: @themeColor !important;
}
.ant-radio-inner::after {
  background-color: @themeColor !important;
}
.addButton {
  position: absolute;
  right: 20px;
  border: 1px solid @themeColor;
  padding: 8px 10px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  color: @themeColor;
  cursor: pointer;
  img {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
}
.commonLabel {
  color: #5e5e5e;
  font-size: 12px;
  display: block;
  width: 100%;
  font-weight: 600;
  // margin-bottom: 5px;
}
.commonTextArea {
  border: none;
  border-bottom: 1px solid #e4e4e4;
  height: 100px;
  padding: 10px;
  width: 100%;
  margin-top: 5px;
  padding-left: 0;
  color: rgba(34, 34, 34, 0.6);
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: rgba(34, 34, 34, 0.6);
  }
}
.commonButton {
  background-color: @themeColor;
  color: #fff;
  padding: 9px 22px;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
  cursor: pointer;
  &.xsButton {
    padding: 3px 10px;
    font-size: 12px;
    border-radius: 5px;
  }
  &:focus{
    outline: none;
  }
  &:hover{
    color: #fff;
  }
}
.commonOutine {
  cursor: pointer;
  background-color: #fff;
  color: @themeColor;
  padding: 8px 22px;
  border: 1px solid @themeColor;
  border-radius: 10px;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
  &:focus{
    outline: none;
  }
}

.actionButton {
  display: flex;
  justify-content: center;
  .view{
    text-decoration: underline;
    &:hover{
      color: #096bef;
    }
  }

  .actionAction {
    width: 25px;
    height: 25px;
    margin: 0 2px;
    display: flex;
    border-radius: 100%;
    background-color: @themeLightColor;
    img {
      width: 14px;
      height: 14px;
      margin: auto;
    }
  }
}
.activeStatus span {
  padding: 5px 13px;
  background: #ecfdf3;
  border-radius: 8px;
  color: #429945;
  font-size: 12px;
  text-transform: uppercase;
}
.stausButton {
  padding: 5px 13px;
  background: #ecfdf3;
  border-radius: 8px;
  color: #429945;
  font-size: 12px;
  text-transform: uppercase;
  &.active {
    color: #429945;
    background: #ecfdf3;
  }
  &.sold {
    background-color: #d8e9ff;
    color: #096bef;
  }
  &.pending {
    background-color: #ffe5fa;
    color: #ea81dd;
  }
  &.return {
    background-color: #feface;
    color: #d8c700;
  }
}
.addFormIcon {
  position: fixed;
  cursor: pointer;
  right: 50px;
  bottom: 50px;
  background: @themeColor;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  z-index: 100;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.34);
  img {
    width: 16px;
    height: 16px;
    margin: auto;
  }
}

.tableDec {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
  max-width: 200px;
  margin: auto;
  color:#545454;
}
.fromToValue .from-group {
  width: 50% !important;
}
.checkBoxForm {
  margin-right: 20px;
  label {
    color: #5e5e5e;
    font-size: 12px;
    display: block;
    width: 100%;
    font-weight: 600;
  }
  .ant-checkbox-wrapper {
    padding: 14px 0;
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: @themeColor;
      border-color: @themeColor;
    }
  }
}
.ant-switch-checked {
  background-color: @themeColor !important;
}
.bottomStickyDropdown {
  display: block;
  .dropdown-toggle {
    background-color: transparent;
    border: none;
    padding: 0;
    line-height: normal;
    margin: 0;
    height: auto;
    color: #212431;
    font-weight: 600;
  }
}
.bottomDropdownWrapper {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 15px;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  background: #fff;
  border: none;
  border-radius: 6px;
  &.show {
    display: block;
  }
  .mailDropdownDetail {
    text-align: center;
    h2 {
      font-size: 16px;
      font-weight: bold;
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
    }
  }
}
.selectCustomerDropdown {
  input {
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    margin-bottom: 10px;
    border: 1px solid #e8e8e8;
  }
  .dropdownAction {
    display: flex;
    justify-content: center;
    button {
      padding: 6px 11px;
      margin: 3px;
      border-radius: 6px;
    }
  }
}

.groupingDrodown {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 6px;
  padding: 0 15px;
  a {
    display: block;
    width: 100%;
    padding: 8px 0;
    color: #343434;
    img {
      width: 14px;
      height: 14px;
      margin-right: 10px;
      &.closeImg {
        width: 14px;
        height: 10px;
      }
    }
  }
}
.enquiryPagesBlock {
  .listingAction {
    display: none !important;
  }
}
.clientPagination ul {
  display: flex;
  padding: 0;
  align-items: center;
}
.clientPagination li {
  list-style: none;
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.clientPagination li img {
  width: 10px;
  height: 10px;
}
.clientPagination li.active {
  background-color: #fff;
  border-radius: 100%;
}
.certifyPopup {
  position: fixed;
  width: calc(~'100% - 60px');
  top: 0;
  background: #d7e0f8;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 5px 32px;
  align-items: center;
  left: 60px;
  p {
    margin-bottom: 0;
    color: @themeColor;
  }
}

.ant-switch {
  min-width: 34px;
  height: 20px;
  &:after {
    width: 16px;
    height: 16px;
  }
}
.ant-tag {
  &.customTag {
    background-color: @themeColor !important;
    border: none !important;
    color: #fff !important;
    border-radius: 5px;
    font-weight: normal;
    margin: 2px;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.5px;
    height: auto;
    line-height: 17px;
  }
}
.comingSoonBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(~'100vh - 100px');
  padding: 20px;
  h2 {
    font-size: 24px;
    font-weight: 600;
  }
}
.w-100 {
  width: 100% !important;
}
.ant-checkbox-inner {
  width: 18px !important;
  height: 18px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @themeColor !important;
  border-color: @themeColor !important;
}

.scrollarea .scrollbar-container.vertical .scrollbar {
  width: 3px !important;
  background: #454545 !important;
  margin-left: 6px !important;
}
.scrollarea .scrollbar-container.vertical {
  background: transparent !important;
}
.d-grid {
  display: grid !important;
}
.customScrollThumb {
  width: 5px !important;
  background: #999 !important;
}
.customScrollHorizontal {
  height: 5px !important;
  background: #999 !important;
}
.fullPageOpen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.width-auto{
  width: auto !important;
}
.width-50 {
  width: 50% !important;
}
.p-15 {
  padding: 15px !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.moreOptionDropdown {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  min-width: 120px;
  a {
    display: block;
    color: #000;
    padding: 8px 0;
    font-weight: 500;
    border: none;
    margin: 0;
    &:hover {
      color: #000;
    }
  }
}
.ant-calendar-picker {
  &:hover {
    input {
      border-bottom: 1px solid @themeColor !important;
    }
  }
  &:focus {
    input {
      outline: none !important;
      box-shadow: none !important;
      border-bottom: 1px solid @themeColor !important;
    }
  }
}
.ant-select-open .ant-select-selection {
  box-shadow: none !important;
  border-bottom: none !important;
}
.ant-calendar-picker-container {
  padding-top: 40px !important;
  .ant-calendar-selected-date .ant-calendar-date,
  .ant-calendar .ant-calendar-ok-btn {
    background: @themeColor !important;
    border: 1px solid @themeColor !important;
    color: #fff !important;
  }
  a {
    color: @themeColor !important;
  }
  .ant-calendar {
    width: 100%;
    .ant-calendar-input-wrap {
      background-color: @themeColor !important;
      .ant-calendar-date-input-wrap {
        margin: 0 !important;
      }
      input {
        background-color: @themeColor !important;
        color: #fff !important;
        text-align: center;
        &::placeholder {
          color: #fff;
          text-align: center;
        }
      }
    }
    .ant-calendar-range-middle {
      color: #fff !important;
      padding-right: 0;
    }
    .ant-calendar-selected-date .ant-calendar-date:hover,
    .ant-calendar-today .ant-calendar-date,
    .ant-calendar-date:hover {
      background: @themeColor;
      border: 1px solid @themeColor !important;
      color: #fff !important;
    }
  }
}
.ant-select-dropdown {
  .ant-select-dropdown-menu-item-active {
    background-color: @themeLightColor !important;
  }
}
.ant-checkbox {
  &:hover {
    .ant-checkbox-inner {
      border: 1px solid @themeColor !important;
    }
  }
}

.align-items-end {
  align-items: flex-end !important;
}
.whiteBg {
  background-color: #fff !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.pt-10 {
  padding-top: 10px;
}

.ant-popover-inner-content {
  .ant-popover-buttons {
    button {
      padding: 2px 12px;
      height: auto;
      margin: 0 5px;

      &.ant-btn-primary {
        background-color: @themeColor !important;
        border: 1px solid @themeColor !important;
      }

      &:hover {
        border: 1px solid @themeColor !important;
      }
    }
  }
}
.ant-modal-confirm {
  padding:0 !important;
  top:0 !important;
  .ant-modal-confirm-body-wrapper{
    .ant-modal-confirm-btns{
      width: 100%;
      display:flex;
      justify-content: center;
      .ant-btn{
        background-color: #fff;
        color:@themeColor !important;
        border: 1px solid @themeColor !important;
        &.ant-btn-primary{
          background-color:@themeColor !important;
          border: 1px solid @themeColor !important;
          color:#fff !important;
        }
      }
    }
  }
}
.boxShadowNone{
  box-shadow: none !important;
}
.mt-50{
  margin-top: 50px;
}
.mr-40{
  margin-right: 40px !important;
}
.width-66{
  width: 66.66% !important;
}
.empty-data {
  text-align: center;
  width: 98%;
  margin: 0 auto;
  height: 88%;
}
.empty-data h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.fixLoader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:105;
  &:after{
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,0.5);
  }
  img{
    height: 80px;
    width: 80px;
    margin: auto;
  }
}
.position-relative{
  position: relative;
}
.ant-col{
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.detailLink{
  text-decoration: underline;
  color: @themeColor;
}

@unit: 100;
.unit-loop(@i) when (@i > 0){
  .mt-@{i} {
    margin-top:~"@{i}px" !important;
  }
  .mr-@{i} {
    margin-right:~"@{i}px" !important;
  }
  .pt-@{i}{
    padding-top:~"@{i}px" !important;
  }
  .unit-loop (@i - 1)
}
.unit-loop (@unit);
.slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list, .slick-slider {
    position: relative;
    display: block;
}
.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-slider .slick-list, .slick-slider .slick-track {
    transform: translateZ(0);
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-initialized .slick-slide {
    display: block;
}

.widthScroll {
  .scrollarea-content {
    width: max-content;
    min-width: 100%;
  }
}
.cursorPointer{
  cursor: pointer;
}
.two-block .from-group  {
  width:49%;
}
.three-block .from-group  {
  width:32%;
}
.four-block .from-group  {
  width:24%;
}
.permissionSessionCount{
  margin-right: 20px;
}
.permissionSessionCount span:first-child{
  font-weight: 500;
  margin-right: 10px;
}
.p-10{
  padding: 10px;
}
.attchImg img{
  width: 100%;
  height: 420px;
  object-fit: contain;
  margin: auto;
}
.mlr-20{
  margin: 0 20px;
}
.targetProgressBar .ant-progress-success-bg, .ant-progress-bg {
  background-color: #ff1818 !important;
}
.targetProgress{
  font-size: 12px;
}
.ant-drawer.ant-drawer-open{
  right: 0;
  transform: translate(0,0) !important;
}
.kycBlockWrapper {
  .ant-time-picker {
    width: 100%;
    .ant-time-picker-input {
      width: 100%;
      border: none;
      border-bottom: 1px solid #e4e4e4;
      border-radius: 0;
      padding: 14px 0;
      height: 45px;
    }
  }
}

.offerPriceHint {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    margin: auto 0;
    .offerPriceHintSquare {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: auto .5rem auto 1rem;
      border-radius: 7px;
      &.client {
        background: red !important;
      }&.admin {
        background: #469c1a !important;
      }
  }
}
