@import 'variables.less';

.salesInputBlock {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  .from-group {
    width: calc(~'25% - 10px');
    padding-right: 10px;
  }
}
.shortCutForm {
  .ant-drawer-content-wrapper {
    padding: 0;
    .mainHeadingTitle {
      padding: 15px 15px 0 15px;
    }
    .addfiledForm {
      padding: 0;
    }
    .searchResultTable {
      height: auto;
      max-height: 250px;
      padding-bottom: 20px;
    }
    .sidebarAction {
      left: 0;
      bottom: 0;
      right: 0;
    }
    .tableInput {
      width: 100%;
      min-width: 100px;
      text-align: center;
      input {
        padding: 5px 5px;
      }
      .ant-select-selection {
        height: 25px;
        .ant-select-selection__rendered {
          line-height: 25px;
        }
      }
      &.width-fix {
        width: 80px;
      }
    }
    .noFillBox {
      cursor: no-drop;
      background: #f1f1f1;
      label {
        cursor: no-drop;
      }
    }
  }
  .shortCutefinalList {
    &.DiamondDetailPopup {
      padding: 0 15px 0 25px;
      .DiamondDetailPopupItem {
        width: 100%;
        justify-content: space-between;
      }
      .inputBlock {
        display: flex;
        align-items: center;
        width: 50%;
        margin-bottom: 5px;
        padding-left: 10px;
        .inputWrapper {
          width: 100%;
          text-align: right;
          input {
            text-align: right;
          }
        }
      }
    }
  }
  .shortCutScroll {
    height: calc(~'100vh - 155px');
    overflow: auto;
  }
}
