body p {
  margin: 0px !important;
}

.main_box {
  padding: 25px 0px;
}
.main_heading {
  text-align: center;
  font-size: 35px;
  padding: 30px;
}
.main_heading p {
  color: #7f5f42;
  font-weight: bold;
  letter-spacing: 1.5px;
}

.form_inputs {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px 10px 0px;
  flex-direction: row;
  align-items: center;
  width: 30%;
}

.form_inputs p {
  margin: 0px !important;
  color: #000000;
  font-size: 20px;
}

.form_inputs input {
  border: 1px solid lightgray;
  border-radius: 6px;
  padding: 10px;
}
.form_inputs button {
  text-align: start;

  height: 38px;
  width: 175px;
}

.select_location {
  display: flex;
  justify-content: space-around;
  padding: 20px 0px;
  flex-direction: row;
  align-items: center;
  width: 50%;
}

.select_location p {
  margin: 0px !important;
  color: #000000;
  font-size: 20px;
}
.response_box {
  border: 3px solid #7f5f42;
  width: fit-content;
  margin: auto;
  background-color: #fff;
}

.box_heading {
  text-align: center;
}
.box_heading p {
  color: #000000;
  padding: 20px;
  font-size: 30px;
  font-weight: 500;
}

.box_content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 15px;
}

.box_content p {
  padding: 10px;
  font-size: 20px;
  font-weight: 500;
}

/* .create_btn {
  padding: 10px;
  background-color: #7f5f42;
  margin: 0;
} */

.note_box {
  padding: 15px;
  border: 3px solid #7f5f42;
  position: fixed;
  bottom: 40px;
  width: fit-content;
  background-color: #fff;
}

.note_box_heading p {
  font-size: 25px;
  font-weight: 500;
  padding: 10px 0px 20px;
  text-decoration: underline;
}

.note_box_content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
}

.note_box div {
  font-size: 18px;
}

.note_element {
  padding: 0px 0px 10px 0px;
}

.note_element li {
  margin-bottom: 5px;
}

.note_link {
  padding: 0px 0px 10px 0px;
  color: #001329 !important;
  text-decoration: underline;
}
.note_url {
  padding: 0px 0px 5px 0px;
  color: #001329 !important;
  font-weight: 500;
}
.copy_clipboard_box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.copy_clipboard {
  background-color: #7f5f42;
  color: #fff;
  border: solid 1px;
  outline: 'none';
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 1px;
}

.showInventoryTable {
  width: 100%;
  background-color: #fff;
}

.userInventory_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userInventory_box p {
  color: #001329 !important;
}

.showInventoryCopy_btn {
  background-color: #7f5f42;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 2px 10px;
  letter-spacing: 1px;
}
.form_box {
  display: flex;
  align-items: center;
  width: 100%;

  flex-direction: column;
}

.create_btn {
  background-color: #7f5f42;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 6px;
  width: 150px;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 1px;
  padding: 10px;
}

.userInv_header {
  width: 100%;
  padding: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  letter-spacing: 1px;
}

.userInv_header h2 {
  padding: 0px 20px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
}

.userInv_header span {
  padding: 0px 10px;
  display: flex;
  align-items: center;
  color: #003365;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}

.userInventory_main {
  background-color: #f3f3f3;
  height: 100vh;
}

.add_btn {
  background-color: #7f5f42;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 6px;
  width: 100px !important;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 1px;
  padding: 10px;
  text-align: center !important;
}
