@import '../../styles/variables.less';

.uploadeDocumentWrapper {
  width: auto;
  background-color: #fafafa;
  // display: inline-block;
  padding: 15px;
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  position: relative;
  border: 1px dashed #d8d8d8;
  .uploadeDocument {
    width: 40px;
    height: 40px;
    margin: auto 15px auto auto;
    border-radius: 5px;
    border: none !important;
    transition: all 0.2s ease-in-out;
    img {
      width: 35px;
      height: 35px;
      // position: absolute;
      // left: 0;
      // right: 0;
      margin: auto;
      // top: 0;
      // bottom: 0;
    }
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    opacity: 0;
    left: 0;
    cursor: pointer;
  }
  .uploadeTitle {
    text-align: center;
    width: 100%;
    margin-top: 0;
    font-weight: 500;
  }
}
.sizeText {
  display: block;
  font-size: 12px;
  margin-top: 7px;
  color: #898989;
  letter-spacing: 0.5px;
}
.error-message-file {
  border: 1px dashed #ee3b3b !important;
}
.error {
  color: #ee3b3b;
  font-size: 10px;
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;