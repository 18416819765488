@import 'variables';
@import 'custom';
@import 'Inventory.less';
@import 'client.less';
@import 'offer.less';
@import 'reports.less';
@import 'searchResult.less';
@import 'inventoryConfiguration.less';
@import 'configuration.less';
@import 'diamondDetail.less';
@import 'savedSearch.less';
// @import 'list.less';
@import 'notes.less';
@import 'quickSearch.less';
@import 'notification.less';
@import 'Profile.less';
@import 'contactDirectory.less';
@import 'compare.less';
@import 'OffceSidebar.less';
@import 'settings.less';
@import 'notificationSingle.less';
@import 'dashboard.less';
@import 'document.less';
@import 'menuPermission.less';
@import 'user.less';
@import 'listingSidebar.less';
@import 'transcation.less';
@import 'KYCList.less';
@import 'Shortcut.less';
@import 'TableExpand.less';
@import 'todo.less';
@import 'EmailSnyc.less';
@import '~antd/dist/antd.less';
@import 'router-tabs.less';
@import 'dna.less';

body {
  margin: 0;
  color: #545454;
  font-size: 14px;
  font-family: 'gilroy';
  font-variant: tabular-nums;
  line-height: 1.3;
  background-color: #fafafa;
  font-feature-settings: 'tnum', 'tnum';
}

@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy-Regular.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy-SemiBold.otf');
  font-weight: 500;
}
@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy-SemiBold.otf');
  font-weight: 600;
}
@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy-Bold.otf');
  font-weight: 700;
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;