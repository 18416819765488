@import 'variables.less';

table {
  tr {
    &.tableInTableBox {
      box-shadow: none !important;
      // border-bottom: none !important;
    }
    td,
    th {
      &:first-child {
        // width: 120px !important;
        // min-width: 120px;
      }
      // flex: 80 0 auto !important;
      // width: 80px !important;
    }
  }
  .tableInTableBoxWrapper {
    // border: 1px solid #f2f2f2;
    background: #fff;
    position: relative;
    padding: 0 10px 10px 10px;
    // width: 100%;
    // display: block;
    &.activityTarget {
      tr {
        td {
          &:first-child {
            &:before,
            &:after {
              content: none;
            }
          }
        }
      }
    }
    tr {
      th {
        padding: 10px 25px;
        text-transform: uppercase;
        font-weight: 500;
        color: #454545;
        font-size: 10px;
        letter-spacing: 0.5px;
        text-align: center;
        flex: 100 0 auto !important;
        width: 120px !important;
      }
    }
    tr {
      box-shadow: none !important;
      border: none !important;
      td {
        position: relative;
        border-right: none !important;
        flex: 100 0 auto !important;
        width: 120px !important;
        &:first-child {
          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 2px;
            background: #e9ebf0;
            height: calc(~'100% + 2px');
            margin-left: -10px;
            top: -19px;
          }
          &::after {
            content: '';
            display: block;
            position: absolute;
            width: 9px;
            height: 2px;
            margin-left: -10px;
            background: #e9ebf0;
            top: 15px;
          }
        }
      }
      &:nth-child(2) {
        td {
          &:first-child {
            &:before {
              height: calc(~'100% + 41px');
              top: -34px;
            }
          }
        }
      }
      // &:nth-child(3) {
      //   background-color: #ecfde2;
      // }
    }
  }
  // tbody{
  //     tr{
  //         &:nth-child(2){
  //             .tableInTableBoxWrapper{
  //                 display: none;
  //             }
  //         }
  //     }
  // }
  // .secondOpen{
  //     tr{
  //         &:nth-child(1){
  //             .tableInTableBoxWrapper{
  //                 display: none;
  //             }
  //         }
  //         &:nth-child(2){
  //             .tableInTableBoxWrapper{
  //                 display: block;
  //             }
  //         }
  //     }
  // }
}
