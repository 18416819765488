@import 'variables.less';

.offerMainPage {
  background: #fff;
  width: 100vw;
  height: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  .offerTopBlock {
    // margin-bottom: 10px;
    padding: 10px;
    .offerTitleWithClient {
      display: flex;
      align-items: center;
      .clientDetail {
        display: flex;
        margin-left: 10px;
        .listdetailBox {
          margin-right: 10px;
        }
      }
      h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
  }
  #chartdiv,
  #chartdiv1 {
    background: @tableBackGroundColor;
  }
}

.offerTopCollpaseOption {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // flex-wrap: wrap;
  max-width: fit-content;
  .collpaseItem {
    display: flex;
    margin: 0 5px;
    .collpaseItemBox {
      transition: background-color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
      display: flex;
      align-items: center;
      position: relative;
      padding: 5px 7px;
      color: #7c828d;
      cursor: pointer;
      font-size: 12px;
      border: 1px solid #d8dce2;
      &:first-child {
        border-radius: 3px 0 0 3px;
      }
      &:last-child {
        border-radius: 0 3px 3px 0;
        border-left: none;
      }
      &.active {
        color: @themeColor;
      }
    }
  }
}
.offerDiffrent {
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  .offerDiffrentItem {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    span {
      &:first-child {
        white-space: nowrap;
        font-size: 12px;
        color: #212121;
        margin-right: 5px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
      &:last-child {
        white-space: nowrap;
        color: #000;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
.totalDisscount {
  margin-right: 10px !important;
  margin-bottom: 5px !important;
  // margin-top: 20px !important;
  input {
    height: 26px;
    border: 1px solid @light-theme-border !important;
    background-color: @inputLightColor;
  }
}

.offerTable {
  .botoomStickyBar {
    bottom: -40px;
    left: 0;
  }
}
.fileUploadeBlock {
  width: 40px;
  height: 40px;
  border: 1px solid #e2e2e2;
  border-radius: 100%;
  display: flex;
  cursor: pointer;
  margin: 0 10px;
  .uploadeFileImg {
    width: 14px;
    margin: auto;
    height: 14px;
    display: block;
  }
  .fileUploade {
    width: 40px;
    opacity: 0;
    height: 40px;
    position: absolute;
  }
}
.moreValue {
  width: 14px;
  height: 14px;
  img {
    width: 14px;
    height: 14px;
  }
}
