@import 'variables.less';

.notificationMainBlock {
  .notificationTable {
    .notificationTableHead {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #e9e9e9;
      position: sticky;
      z-index: 1;
      background-color: #fff;
      top: -20px;
      .notifiHeadeItem {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 14px;
        font-weight: 600;
        padding: 20px 0;
        text-align: center;
        white-space: nowrap;
        width: 16%;
        &:first-child {
          flex: 1;
          font-size: 18px;
          text-align: left;
          width: 36%;
        }
      }
    }
    .notifiDetails {
      display: flex;
      flex-direction: row;
      .notifiDetailItem {
        display: flex;
        text-align: center;
        flex-direction: column;
        width: 16%;
        white-space: nowrap;
        border-bottom: 1px solid #e9e9e9;
        padding: 20px 0;
        cursor: pointer;
        .notificationtooltipBlock {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .notificationRadio {
            display: block;
            width: 100%;
            .ant-radio-group {
              display: block;
              label {
                display: block;
                margin: 10px 0;
              }
            }
          }
          .notifiSelect {
            display: flex;
            flex-wrap: wrap;
            .ant-select {
              margin: 0 10px 0 0;
            }
            .ant-select-selection-selected-value {
              white-space: nowrap;
              overflow: hidden;
              display: block !important;
              text-overflow: ellipsis;
            }
            .ant-select-arrow {
              svg {
                fill: @themeColor;
              }
            }
          }
          .notifiTime {
            input {
              width: 38px;
              height: 30px;
              border-radius: 5px;
              text-align: center;
              margin: 0 2px;
              padding: 5px;
              border: 1px solid #ddd;
            }
            .ant-select {
              margin-left: 5px;
            }
          }
          img {
            cursor: pointer;
            width: 14px;
            height: 14px;
            margin: 0 0 0 5px;
            &.doubleTick {
              width: 24px;
              height: 24px;
              visibility: hidden;
              opacity: 0;
            }
          }
        }
        .notificationMTwoImg {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin: 0 10px;
            &.volumeImg {
              width: 20px;
              height: 20px;
            }
          }
          .requireFile {
            color: rgb(220, 110, 123);
            font-size: 20px;
          }
        }
        img {
          width: 25px;
          margin: auto;
          height: 25px;
          cursor: pointer;
        }
        &:first-child {
          flex: 1;
          width: 36%;
          text-align: left;
        }
      }
      &:hover {
        .notifiDetailItem {
          .notificationtooltipBlock {
            img {
              &.doubleTick {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
}
