@import 'variables.less';

.moreTabScroll {
  display: flex;
  flex-wrap: wrap;
  .customerMaintab {
    width: calc(~'100% - 80px');
    border-bottom: none;
    padding: 0 5px;
  }
}
.configurationWrapper {
  display: block;
  width: 100%;
  border-top: 1px solid #efefef;
  padding-top: 15px;
}
