@import '../../styles/variables.less';
.officeBookWrapper {
  .leftSelectItem {
    margin: 15px 0;
    &:first-child {
      margin-top: 0;
    }
  }
}
.leftSelectItem,
.selectValueItem {
  margin: 0 15px 10px 15px;
  .leftSelectTitle {
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 16px;
      font-weight: 600;
      color: @leftOptionLabel;
    }
    .reloadImg {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
  .leftMultiSelect {
    width: 100%;
    .ant-select-selection {
      width: 100%;
      background: @leftFilterOptionbg;
      border: none;
      padding: 8px;
      height: auto;
      border-radius: 10px !important;
      &.ant-select-selection {
        padding: 8px;
        &.ant-select-selection--multiple {
          .ant-select-selection__rendered {
            ul {
              li {
                margin: 2px;
              }
            }
          }
        }
        .ant-select-selection__placeholder {
          color: #000;
        }
        .ant-select-selection-selected-value {
          color: #000;
          font-size: 14px;
        }
      }
    }
  }

  .SelectDateRange {
    width: 100%;
    .ant-calendar-picker-input {
      background: @leftFilterOptionbg;
      border: none;
      border-radius: 10px !important;
      padding: 13px 10px;
      height: auto;
      color: #000;
    }
    input {
      color: #000;
      &::placeholder {
        color: #000;
      }
    }
    &.ant-calendar-picker {
      &:hover {
        input {
          border: none !important;
        }
      }
    }
  }

  .error {
    color: #ee3b3b;
    font-size: inherit;
    margin-top: 3px;
  }
}

.selectImageValue {
  display: flex;
  flex-wrap: wrap;
  .selectValueItem {
    margin-right: 10px;
    margin-bottom: 10px;
    min-width: calc(~'33.33% - 10px');
    position: relative;
  }
  .selectShapeValue {
    width: 100%;
    display: flex;
    align-items: center;
    .slectShape {
      background-color: @tableBackGroundColor;
      width: auto;
      min-width: 50px;
      text-align: center;
      padding: 8px 10px;
      border-radius: 5px;
      margin: 5px;
      cursor: pointer;
      &.active {
        background-color: @themeColor;
        color: #fff;
      }
    }
  }
  .SelectFull {
    width: 100%;
    margin: 15px 10px 0 10px;
    position: relative;
    .selectShapeValue {
      .slectShape {
        margin: 0;
        width: 100%;
        padding-right: 25px;
        text-align: left;
        background-color: @lightCardBg;
      }
    }
    &:after {
      content: '';
      width: 4px;
      height: 16px;
      background: transparent;
      position: absolute;
      border-radius: 0 5px 5px 0;
      margin: auto;
      left: 0;
      top: 0;
      bottom: 0;
    }
    &.active {
      .selectShapeValue {
        .slectShape {
          background-color: #fff;
          box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.12);
        }
      }
      &:after {
        background-color: @themeColor;
      }
    }
  }
  .selectClose {
    position: absolute;
    right: 10px;
    top: 2px;
    bottom: 0;
    margin: auto;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    display: flex;
    cursor: pointer;
    background-color: @tableBackGroundColor;
    img {
      margin: auto;
      width: 6px;
      height: 6px;
    }
  }
  .selectUserList {
    background-color: @tableBackGroundColor;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      display: block;
    }
  }
}

.leftInputBlock {
  margin: 0 15px 20px 15px;
  label {
    font-size: 16px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
    color: @leftOptionLabel;
  }
  input {
    width: 100%;
    background: @leftFilterOptionbg;
    border: none;
    padding: 14px 20px;
    height: auto;
    border-radius: 10px;
    color: #000;
    &::placeholder {
      color: #000;
    }
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;