@import 'variables';

.dn-tab-wrapper {
  &.dark {
    .customerMaintab {
      background-color: black !important;

      .react-router-tabs {
        color: #ffffff;

        &.active {
          color: #8c7459;
        }
      }
    }
  }

  .customerMaintab,
  .customerSubTab {
    > * {
      margin-top: auto;
      margin-bottom: auto;
    }

    .react-router-tabs {
      &.active {
        font-weight: 600;
      }
    }

    .subTabInnerDropdown {
      display: none;
      position: fixed;
      z-index: 100000;
      border-radius: 10px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
      background-color: #fff;
      padding: 10px;

      ul {
        margin: 0;
        padding: 0;

        li {
          padding: 10px;
          list-style: none;
          text-transform: capitalize;
          letter-spacing: 0.5px;
          white-space: nowrap;

          a {
            color: #292d34 !important;
            font-size: 14px;
            font-weight: 600;
          }

          &.active {
            border-radius: 5px;
            background-color: @themeColor;

            a {
              color: #fff !important;
            }
          }
        }
      }
    }

    li,
    a {
      color: #6e6e6e;
      cursor: pointer;

      &:hover {
        .subTabInnerDropdown {
          display: block;
        }
      }
    }
  }

  .customerMaintab {
    display: flex;
    padding: 0 15px;
    height: 48px;
    border-bottom: 1px solid #efefef;

    .scrollarea {
      width: 300px;

      .scrollarea-content {
        display: flex;
      }
    }

    .mainHeadingTitle {
      margin: 0 25px 0 0;
      padding: 0;
      font-size: 18px;
      font-weight: 600;
    }

    .headerInfoIcon {
      margin-right: 5px;
      cursor: pointer;
      width: 12px;
      height: 12px;
    }

    > .react-router-tabs {
      position: relative;

      padding: 15px 8px;
      list-style: none;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1.5px;
      white-space: nowrap;
      font-size: 12px;

      &.active {
        font-weight: 600;
        color: #003365;
      }
    }
  }

  .customerSubTab {
    display: flex;
    height: 50px;
    padding: 0 10px;
    background-color: #f8f8f8;
    border: 1px solid #efefef;

    li,
    a {
      color: #545454;
    }

    .customerSubTab a {
      position: relative;
      list-style: none;
      padding: 0 10px 0 10px;
    }

    .react-router-tabs {
      position: relative;
      padding: 0 10px 0 10px;
      list-style: none;
      text-decoration: none;
      letter-spacing: auto;
      white-space: nowrap;

      &.active {
        font-weight: 600;
      }
    }
  }
}
